<template>
    <div class="Home">
        <!-- HERO -->
        <page-header/>

        <section class="uk-section uk-section-small uk-padding-remove">
            <div class="uk-container">
                <div class="uk-height-medium uk-cover-container uk-border-rounded"
                     v-if="$store.getters.currentUser.organizs[0].organizCover != null">
                    <img class="uk-width-1-1"
                         v-if="$store.getters.currentUser.organizs[0].organizCover.includes('http')"
                         :src="$store.getters.currentUser.organizs[0].organizCover"
                         alt="Alt img"
                         data-uk-cover>
                  <img class="uk-width-1-1"
                       v-else-if="$store.getters.currentUser.organizs[0].organizCover.includes('https')"
                       :src="$store.getters.currentUser.organizs[0].organizCover"
                       alt="Alt img"
                       data-uk-cover>
                    <img class="uk-width-1-1"
                         v-else
                         :src="'/uploadingDir/organizicons/'+$store.getters.currentUser.organizs[0].organizCover"
                         alt="Alt img"
                         data-uk-cover>
                    <div class="uk-overlay uk-overlay-primary uk-position-cover uk-flex uk-flex-center uk-flex-middle uk-light uk-text-center">
                        <div data-uk-scrollspy="cls: uk-animation-slide-bottom-small">
                            <h1 class="uk-margin-top uk-margin-small-bottom uk-margin-remove-adjacent"
                            >{{$store.getters.currentUser.organizs[0].organizName}}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="uk-section uk-section-small">
            <div class="uk-container">
                <div uk-grid class="uk-child-width-1-1@s uk-child-width-1-2@m uk-child-width-1-5@xl">
                    <div>
                        <div class="uk-card uk-card-default uk-card-body uk-text-center uk-padding-remove-bottom"
                             style="background-color: #a3daff">
                            <span class="statistics-text">{{$t("organization.home.tques")}}</span><br/><br/>
                            <span class="statistics-number">( {{totalQuestion}} ) </span><br/>
                            <br/>
                            <div class="uk-card-footer uk-padding-small">
                                <router-link class="uk-button uk-button-text" tag="a"
                                             :to="{name: 'OrganizationQuestionList'}">
                                    {{$t("organization.home.sDetail")}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="uk-card uk-card-default uk-card-body uk-text-center uk-padding-remove-bottom"
                             style="background-color: #d3e0f7">
                            <span class="statistics-text">{{$t("organization.home.fexam")}}</span><br/><br/>
                            <span class="statistics-number">( {{totalFreeExam}} ) </span><br/>
                            <br/>
                            <div class="uk-card-footer uk-padding-small">
                                <router-link class="uk-button uk-button-text" tag="a"
                                             :to="{name: 'OrganizationExamList'}">{{$t("organization.home.sDetail")}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="uk-card uk-card-default uk-card-body uk-text-center uk-padding-remove-bottom"
                             style="background-color: #dedcee">
                            <span class="statistics-text">{{$t("organization.home.pexam")}}</span><br/><br/>
                            <span class="statistics-number">( {{totalPayedExam}} ) </span><br/>
                            <br/>

                            <div class="uk-card-footer uk-padding-small">
                                <router-link class="uk-button uk-button-text" tag="a"
                                             :to="{name: 'OrganizationExamList'}">{{$t("organization.home.sDetail")}}
                                </router-link>

                            </div>
                        </div>
                    </div>


                    <div>
                        <div class="uk-card uk-card-default uk-card-body uk-text-center uk-padding-remove-bottom"
                             style="height: 100%; background-color:#d8e9ef">
                            <span class="statistics-text">  {{$t("organization.home.prof")}}</span><br/><br/>
                            <span class="statistics-number">( {{totalProfessor}} ) </span><br/>
                            <br/>

                            <div class="uk-card-footer uk-padding-small">

                                <router-link class="uk-button uk-button-text" tag="a"
                                             :to="{name: 'professorlist', params:{id:0}}">
                                    {{$t("organization.home.sDetail")}}
                                </router-link>

                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="uk-card uk-card-default uk-card-body uk-text-center uk-padding-remove-bottom"
                             style="height: 100%; background-color:#79bd9a">
                            <span class="statistics-text">  {{$t("organization.home.student")}}</span><br/><br/>
                            <span class="statistics-number">( {{totalStudent}} ) </span><br/>
                            <br/>
                            <div class="uk-card-footer uk-padding-small uk-text-center">
                                <router-link class="uk-button uk-button-text " tag="a"
                                             :to="{name: 'studentlist', params:{id:0}}">
                                    {{$t("organization.home.sDetail")}}
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <!--                    <div>-->
                    <!--                        <div class="uk-card uk-card-default uk-card-body uk-text-center uk-padding-remove-bottom"-->
                    <!--                             style="background-color:#a3daff">-->
                    <!--                            <span class="statistics-text"> {{$t("organization.home.nRequest")}}</span><br/><br/>-->
                    <!--                            <span class="statistics-number">( {{totalNewRequest}} ) </span><br/>-->
                    <!--                            <br/>-->

                    <!--                            <div class="uk-card-footer uk-padding-small">-->

                    <!--                                <router-link class="uk-button uk-button-text"-->

                    <!--                                             title="Student new requests" tag="a"-->
                    <!--                                             :to="{name: 'studentrequest'}">{{$t("organization.home.sDetail")}}-->
                    <!--                                </router-link>-->

                    <!--                            </div>-->
                    <!--                        </div>-->
                    <!--                    </div>-->

                </div>
            </div>

        </section>
        <!-- HERO -->
        <!-- tseekuu ADD calendar -->
        <section class="uk-margin-top">
            <div class="uk-container">
                <full-calendar :config="config" :header="header" :events="events"
                               ref="calendar"></full-calendar>
            </div>
        </section>
        <section class="uk-container uk-margin-top" v-if="maincates!=null && maincates.length > 0">
            <div uk-slider="finite: true">
                <div class="uk-grid-small uk-flex-middle uk-margin-bottom" uk-grid>
                    <h2 class="uk-width-expand uk-text-center uk-text-left@s">
                        {{$t("organization.home.fPic")}}</h2>
                    <h5>
                        <router-link title="market" tag="a"
                                :to="{name: 'datamarket',query: { mtype0: '1'  }}">
                          {{$t("organization.home.vMore")}}
                        </router-link>
                    </h5>
                    <div class="uk-visible@s">
                        <a class="tm-slidenav" href="#" uk-slider-item="previous" uk-slidenav-previous></a>
                        <a class="tm-slidenav" href="#" uk-slider-item="next" uk-slidenav-next></a>
                    </div>
                </div>
                <div>
                    <div class="uk-card uk-card-default uk-card-small tm-ignore-container">
                        <div class="uk-position-relative">
                            <div class="uk-slider-container">
                                <div class="uk-slider-items uk-grid-collapse uk-child-width-1-3 uk-child-width-1-4@m tm-products-grid">
                                    <article
                                            v-for="(item, index) in maincates"
                                            :key="index"
                                            class="tm-product-card">
                                        <div class="tm-product-card-media">
                                            <div class="tm-ratio tm-ratio-4-3">
                                                <router-link
                                                        class="tm-media-box"
                                                        :title="item.name"
                                                        tag="a"
                                                        :to="{ name: 'category',params: { slug: slugify(item.name),
                                                            catid: item.id},query:{mtype0: '1'}}" >
                                                    <figure class="tm-media-box-wrap uk-background-secondary uk-light uk-padding uk-panel">
                                                        <p class="uk-position-center uk-position-z-index uk-text-center uk-width-1-1"
                                                                style="background: rgba(0,0,0,0.67); width: 100%">{{item.name}}</p>
                                                        <img :src="item.image" :alt="item.name">
                                                    </figure>
                                                </router-link>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin-top uk-hidden@s"></ul>
                </div>
            </div>
        </section>

        <br>
<!--        <section class="uk-container" v-if="maincates!=null && maincates.length > 0">-->
<!--            <div uk-slider="finite: true">-->
<!--                <div class="uk-grid-small uk-flex-middle uk-margin-bottom" uk-grid>-->
<!--                    <h2 class="uk-width-expand uk-text-center uk-text-left@s">-->
<!--                        {{$t("organization.home.rStack")}}</h2>-->
<!--                    <h5>-->
<!--                        <router-link tag="a" :to="{name: 'datamarket',query: {mtype0: '4'}}"-->
<!--                        > {{$t("organization.home.vMore")}}-->
<!--                        </router-link>-->
<!--                    </h5>-->
<!--                    <div class="uk-visible@s">-->
<!--                        <a class="tm-slidenav" href="#" uk-slider-item="previous" uk-slidenav-previous></a>-->
<!--                        <a class="tm-slidenav" href="#" uk-slider-item="next" uk-slidenav-next></a>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div>-->
<!--                    <div class="uk-card uk-card-default uk-card-small tm-ignore-container">-->
<!--                        <div class="uk-position-relative">-->
<!--                            <div class="uk-slider-container">-->
<!--                                <div class="uk-slider-items uk-grid-collapse uk-child-width-1-3 uk-child-width-1-4@m tm-products-grid">-->
<!--                                    <article v-for="(item, index) in maincates"-->
<!--                                            :key="index" class="tm-product-card">-->
<!--                                        <div class="tm-product-card-media">-->
<!--                                            <div class="tm-ratio tm-ratio-4-3">-->
<!--                                                <router-link-->
<!--                                                        class="tm-media-box"-->
<!--                                                        :title="item.name"-->
<!--                                                        tag="a"-->
<!--                                                        :to="{ name: 'category',params: { slug: slugify(item.name),-->
<!--                                                        catid: item.id},query:{mtype0: '4'}}">-->
<!--                                                    <figure class="tm-media-box-wrap uk-background-secondary uk-light uk-padding uk-panel">-->
<!--                                                        <p  class="uk-position-center uk-position-z-index uk-text-center uk-width-1-1"-->
<!--                                                                style="background: rgba(0,0,0,0.67); width: 100%">{{item.name}}</p>-->
<!--                                                        <img :src="item.image" :alt="item.name">-->
<!--                                                    </figure>-->
<!--                                                </router-link>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </article>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin-top uk-hidden@s"></ul>-->
<!--                </div>-->
<!--            </div>-->
<!--        </section>-->
<!--        <br>-->
<!--        <section class="uk-container" v-if="maincates!=null && maincates.length > 0">-->
<!--            <div uk-slider="finite: true">-->
<!--                <div class="uk-grid-small uk-flex-middle uk-margin-bottom" uk-grid>-->
<!--                    <h2 class="uk-width-expand uk-text-center uk-text-left@s">-->
<!--                        {{$t("organization.home.fVideo")}}</h2>-->
<!--                    <h5>-->
<!--                        <router-link  tag="a"-->
<!--                                :to="{name: 'datamarket',query: { mtype0: '2'}}"-->
<!--                        >{{$t("organization.home.vMore")}}-->
<!--                        </router-link>-->
<!--                    </h5>-->
<!--                    <div class="uk-visible@s">-->
<!--                        <a class="tm-slidenav" href="#" uk-slider-item="previous" uk-slidenav-previous></a>-->
<!--                        <a class="tm-slidenav" href="#" uk-slider-item="next" uk-slidenav-next></a>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div>-->
<!--                    <div class="uk-card uk-card-default uk-card-small tm-ignore-container">-->
<!--                        <div class="uk-position-relative">-->
<!--                            <div class="uk-slider-container">-->
<!--                                <div-->
<!--                                        class="uk-slider-items uk-grid-collapse uk-child-width-1-3 uk-child-width-1-4@m tm-products-grid"-->
<!--                                >-->
<!--                                    <article-->
<!--                                            v-for="(item, index) in maincates"-->
<!--                                            :key="index"-->
<!--                                            class="tm-product-card"-->
<!--                                    >-->
<!--                                        <div class="tm-product-card-media">-->
<!--                                            <div class="tm-ratio tm-ratio-4-3">-->
<!--                                                <router-link-->
<!--                                                        class="tm-media-box"-->
<!--                                                        :title="item.name"-->
<!--                                                        tag="a"-->
<!--                                                        :to="{ name: 'category',params: { slug: slugify(item.name),-->
<!--                                      catid: item.id},query:{mtype0: '2'}}"-->
<!--                                                >-->
<!--                                                    <figure-->
<!--                                                            class="tm-media-box-wrap uk-background-secondary uk-light uk-padding uk-panel"-->
<!--                                                    >-->
<!--                                                        <p-->
<!--                                                                class="uk-position-center uk-position-z-index uk-text-center uk-width-1-1"-->
<!--                                                                style="background: rgba(0,0,0,0.67); width: 100%"-->
<!--                                                        >{{item.name}}</p>-->
<!--                                                        <img :src="item.image" :alt="item.name">-->
<!--                                                    </figure>-->
<!--                                                </router-link>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </article>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin-top uk-hidden@s"></ul>-->
<!--                </div>-->
<!--            </div>-->
<!--        </section>-->

<!--        <section class="uk-container uk-margin-top uk-margin-bottom" v-if="faudios!=null && faudios.length>0" >-->
<!--            <div class="uk-grid-small uk-flex-middle uk-margin-bottom uk-grid uk-height-1-1">-->
<!--                <h2 class="uk-width-expand uk-text-center uk-text-left@s-->
<!--          uk-first-column">{{$t("organization.home.fMusic")}}</h2>-->
<!--                <h5>-->
<!--                    <router-link-->
<!--                            tag="a"-->
<!--                            :to="{name: 'datamarket',query: {-->
<!--              mtype0: '3'-->
<!--            }}"-->
<!--                    >{{$t("organization.home.vMore")}}-->
<!--                    </router-link>-->
<!--                </h5>-->
<!--                <div class="uk-visible@s"></div>-->
<!--            </div>-->
<!--            <div class="uk-overflow-auto">-->
<!--                <table-->
<!--                        class="uk-table uk-table-hover uk-table-striped uk-text-center uk-table-responsive border-table"-->
<!--                >-->
<!--                    <thead>-->
<!--                    <tr>-->
<!--                        <th class="uk-text-center uk-text-bold">{{$t("organization.home.field")}}</th>-->
<!--                        <th class="uk-text-center uk-text-bold">{{$t("organization.home.audio")}}</th>-->
<!--                        <th class="uk-text-center uk-text-bold">{{$t("organization.home.info")}}</th>-->
<!--                    </tr>-->
<!--                    </thead>-->

<!--                    <tbody>-->
<!--                    <tr v-for="(item, index) in faudios" :key="index">-->
<!--                        <td>-->
<!--                            <router-link-->
<!--                                    class="uk-text-emphasis"-->
<!--                                    :title="item.title"-->
<!--                                    tag="a"-->
<!--                                    :to="{-->
<!--                    name: 'answer',-->
<!--                    params: {-->
<!--                      answerId: item.id-->
<!--                    }-->
<!--                  }"-->
<!--                            >{{item.title}}-->
<!--                            </router-link>-->
<!--                        </td>-->
<!--                        <td>-->
<!--                            <vue-plyr data-width="500" data-height="300" v-if="item.datatype == 3">-->
<!--                                <audio>-->
<!--                                    <source :src="item.imageurl" type="audio/mp3">-->
<!--                                    <source :src="item.imageurl" type="audio/ogg">-->
<!--                                </audio>-->
<!--                            </vue-plyr>-->
<!--                        </td>-->
<!--                        <td>-->
<!--                            <router-link-->
<!--                                    v-for="(keyitem, keyindex) in item.keywords"-->
<!--                                    :key="keyindex"-->
<!--                                    class="uk-button uk-button-default uk-button-small uk-margin-small-right uk-margin-small-bottom"-->
<!--                                    title="keyitem"-->
<!--                                    tag="a"-->
<!--                                    :to="{name: 'datamarket',-->
<!--                    query: {-->
<!--              mtype0: '3',-->
<!--              keyword:keyitem-->
<!--            }}"-->
<!--                            >{{keyitem}}-->
<!--                            </router-link>-->
<!--                        </td>-->
<!--                    </tr>-->
<!--                    </tbody>-->
<!--                </table>-->
<!--            </div>-->
<!--        </section>-->
        <section class="uk-section uk-padding-remove">
            <div class="uk-container">
                <div class="uk-grid-small" uk-grid>
                    <div class="uk-width-1-1@m uk-padding-remove uk-margin-small-left">
                        <div class="border-blue uk-padding-small uk-text-small uk-flex uk-flex-center uk-width-1-1 uk-margin-bottom">
                            <div class="uk-flex-middle " uk-grid>
                                <div class="uk-width-1-1 uk-flex-middle  uk-margin-remove" uk-grid>
                                    <div>
                                        <img  src="../../assets/UBT.png"
                                                width="80" id="logo">
                                    </div>
                                    <div class="uk-width-expand">
                                        <a href="/uploadingDir/cloud_user_app.apk" >
                                            <span>{{$t("organization.home.apkDown")}}</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--//modal admin login-->
            <div id="modal-adminlogin" uk-modal>
                <div class="uk-modal-dialog uk-modal-body">
                    <h2 class="uk-modal-title">Admin Login</h2>
                    <div class="uk-margin">
                        <div class="uk-inline uk-width-1-1">
                            <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: user"></span>
                            <input v-model="adminid" placeholder="Admin id" class="uk-input" type="text"/>
                        </div>
                    </div>
                    <div class="uk-margin">
                        <div class="uk-inline uk-width-1-1">
                            <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: lock"></span>
                            <input v-model="password" placeholder="Password" class="uk-input" type="password"/>
                        </div>
                    </div>
                    <p class="uk-text-right">
                        <button type="button" class="uk-button uk-button-default uk-modal-close" >Cancel</button>
                        <button type="button" @click="sendlogin" class="uk-button  uk-button-primary" >Login</button>
                    </p>
                </div>

            </div>
            <!--//examinfo-->
            <div id="modal-examinfo" uk-modal>
                <div v-if="roominfo!=null&examinfo!=null" class="uk-modal-dialog uk-modal-body">
                    <!--<h2 class="uk-modal-title">Exam Info</h2>-->
                    <div class="uk-margin">
                        <h3>Room info</h3>
                        <hr>
                        <p><b>Room code: </b>{{roominfo.result.room_code}}</p>
                        <p><b>Room name: </b>{{roominfo.result.examroom_name}}</p>
                        <p><b>Room number: </b>{{roominfo.result.examroom_num}}</p>
                        <p><b>Season form code: </b>{{roominfo.result.season_form_code}}</p>
                        <p><b>Exam name: </b>{{roominfo.result.exam_name}}</p>
                        <p><b>Exam status: </b>{{roominfo.result.exam_status}}</p>
                        <p><b>Assign super name: </b>{{roominfo.result.assign_super_name}}</p>
                        <h3>Exam info</h3>
                        <hr>
                        <!--<p>{{examinfo.result.cate_name}}</p>-->
                        <p><b>Question count: </b>{{examinfo.result.question_count}}</p>
                        <p><b>Exam total time: </b>{{examinfo.result.season_exam_total_time}}</p>
                        <p><b>Start time code: </b>{{examinfo.result.exam_start_time_code}}</p>
                        <p><b>End time code: </b>{{examinfo.result.exam_end_time_code}}</p>
                        <p><b>Exam status: </b>{{examinfo.result.exam_status}}</p>
                        <p><b>Room code: </b>{{examinfo.result.room_code}}</p>
                        <p><b>Season from code: </b>{{examinfo.result.season_form_code}}</p>
                        <p><b>Count: </b>{{examinfo.result.count}}</p>
                    </div>
                    <p class="uk-text-right">
                        <button type="button" @click="examStatusChange('ready')" class="uk-button uk-button-default" >
                            Ready
                        </button>
                        <button type="button" @click="examStatusChange('start')" class="uk-button uk-button-default" >
                            Start
                        </button>
                        <button type="button" @click="examStatusChange('end')" class="uk-button uk-button-default" >End
                        </button>

                        <button type="button" @click="examStatusChange('finish')" class="uk-button uk-button-default" >
                            Finish
                        </button>
                        <button type="button" @click="createExamnee()" class="uk-button uk-button-default" > Examnee
                            create
                        </button>

                        <button type="button" class="uk-button uk-button-default uk-modal-close" >Cancel</button>

                    </p>
                </div>
            </div>


            <div id="modal-examcodes" uk-modal>
                <div v-if="examcodes!=null" class="uk-modal-dialog uk-modal-body">
                    <h2 class="uk-modal-title">Exam Codes</h2>
                    <div class="uk-margin">
                        <p v-for="(item, index) in examcodes" :key="index"><b>Exam code: </b>{{item.examCode}}-- <b>User
                            name: </b>{{item.examineeName}}</p>
                    </div>
                    <p class="uk-text-right">
                        <button type="button" class="uk-button uk-button-default uk-modal-close" >Cancel</button>
                    </p>
                </div>
            </div>
        </section>

        <page-footer/>

    </div>
</template>

<script scoped>
    import {mapGetters} from 'vuex'
    import OrganizService from '@/services/OrganizService'
    import ExamService from '@/services/ExamService'
    import UIkit from 'uikit'
    import {FullCalendar} from 'vue-full-calendar'
    import 'fullcalendar/dist/fullcalendar.css'
    // import CategoryService from '@/services/CategoryService'
    // import AnswersService from '@/services/AnswersService'
    import PageHeader from '@/components/Organizcmp/OrganizHeader.vue'

    const PageFooter = () => import("../Organizcmp/OrganizFooter.vue")

    export default {
        name: 'Home',
        components: {
            FullCalendar,
            PageHeader,
            PageFooter
        },
        computed: {
            ...mapGetters(['isLoggedIn', 'cartValue', 'currentUser', 'cartItemList'])
        },
        data() {
            return {
                room_code: null,
                events: [],
                calendarList: [],
                error: null,
                exams: null,
                adminid: '',
                password: '',
                examinfo: null,
                roominfo: null,
                infodata: 'not found data',
                examcodes: null,
                totalQuestion: 0,
                totalFreeExam: 0,
                totalStudent: 0,
                totalPayedExam: 0,
                totalProfessor: 0,
                totalNewRequest: 0,
                header: {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'month,agendaWeek,agendaDay'
                },
                config: {
                    locale: 'en',
                    default: 'month',
                    // theme: false,
                    firstDay: 0,
                    isRTL: false,
                    weekends: true,
                    defaultView: 'month',
                    // fixedWeekCount: true,
                    // weekNumbers:false,
                    // height: 'auto',
                    // contentHeight:'auto',
                    // aspectRatio:'1.35',
                    // eventLimit:'False',
                    // drop(...args) {
                    // },
                },
                maincates: null,
                faudios: null,
                keywords: null,
                filtereddatas: null,
                datatype: 1,
                classes: [
                    {class: 'image'},
                    {class: 'video'},
                    {class: 'audio'},
                    {class: 'stack'}
                ],
            }
        },
        created() {
            this.getHomeData()
            this.getCalendarList()
        },
        mounted() {
            // this.loadFeaturedData()
        },
        methods: {
            async getHomeData() {
                try {
                    const response = await OrganizService.getHomeData({
                        organizId: this.currentUser.organizs[0].organizId
                    })
                    if (response.data.status == 200) {
                        if (response.data.result) {
                            this.totalQuestion = response.data.result.totalQuestion
                            this.totalFreeExam = response.data.result.fExamTotal
                            this.totalStudent = response.data.result.studentCount
                            this.totalPayedExam = response.data.result.pExamTotal
                            this.totalProfessor = response.data.result.professorCount
                            this.totalNewRequest = response.data.result.newRequest
                        }
                    }
                } catch (error) {
                    console.log(error)
                }
            },
            async examStatusChange(status) {
                try {
                    const response = await OrganizService.examstatusChange({
                        adminid: this.adminid,
                        examstatus: status,
                        roomcode: this.examinfo.result.room_code,
                        examroomname: this.roominfo.result.examroom_name,
                        seasonformcode: this.examinfo.result.season_form_code,
                        action: status
                    })

                    if (response.data.status === 200) {
                        alert("success")
                        let res = JSON.parse(response.data.message)
                        this.roominfo.result.room_code = res.result.room_code
                        this.roominfo.result.exam_status = res.result.exam_status
                        this.examinfo.result.room_code = res.result.room_code
                        this.examinfo.result.exam_status = res.result.exam_status
                    }
                } catch (error) {
                    console.log(error)
                    this.error = error.response.data.error
                    alert(this.error)
                }
            },
            async examInfo(adminid, password) {
                this.examinfo = null
                this.roominfo = null
                try {
                    const response = await OrganizService.examInfo({
                        adminid: adminid,
                        examnum: password
                    })

                    if (response.data.status === 200) {
                        this.examinfo = JSON.parse(response.data.message)
                        this.roomInfo(adminid, password)
                    }
                } catch (error) {
                    this.error = error.response.data.error
                    alert(this.error)
                }
            },
            async roomInfo(adminid, password) {
                try {
                    const response = await OrganizService.roomInfo({
                        adminid: adminid,
                        examnum: '',
                        password: password
                    })
                    if (response.data.status === 200) {
                        this.roominfo = JSON.parse(response.data.message)
                   }
                } catch (error) {
                    console.log(error)
                    this.error = error.response.data.error
                    alert(this.error)
                }
            },
            async getCalendarList() {
                try {
                    const response = await OrganizService.getCalendarList({
                        organizId: this.currentUser.organizs[0].organizId
                    })
                    if (response.data.status == 200) {
                      console.log("response.data.result")
                      console.log(response.data.result)
                        response.data.result.exams.forEach(item => {
                            var exam = {}
                            exam.title = item.examName
                            exam.event = item.examStatus
                            exam.date = item.examSdate
                            exam.days = this.timerCount(item.examSdate, item.examEdate)
                            this.events.push(exam)
                        })
                    }
                } catch (error) {
                    console.log(error)
                }
            },
            timerCount: function (start, end) {
                var startt = new Date(start).getTime()
                var endd = new Date(end).getTime()
                var now = new Date().getTime();

                var distance = startt - now;
                var passTime = endd - now;

                if (distance < 0 && passTime < 0) {
                    return 'expired';
                    //expired
                } else if (distance < 0 && passTime > 0) {
                    return 'D-' + this.calcTime(passTime);
//running
                } else if (distance > 0 && passTime > 0) {
                    return 'D-' + this.calcTime(distance);
//upcoming
                }
            },
            calcTime: function (dist) {
                // Time calculations for days, hours, minutes and seconds
                return Math.floor(dist / (1000 * 60 * 60 * 24)); //days
                // this.hours = Math.floor((dist % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                // this.minutes = Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60));
                // this.seconds = Math.floor((dist % (1000 * 60)) / 1000);
            },
            async sendlogin() {
                try {
                    if (this.adminid.length == 0) {
                        alert("please enter admin id")
                        return;
                    }

                    if (this.password.length == 0) {
                        alert("please enter password")
                        return;
                    }
                    const response = await OrganizService.adminLogin({
                        id: this.adminid,
                        password: this.password
                    })

                    if (response.data.status === 200) {

                        let loginres = JSON.parse(response.data.message)

                        console.log(loginres.adminLogin.result)
                        if (loginres.adminLogin.result.status === 'success') {
                            UIkit.modal('#modal-adminlogin', {
                                modal: false,
                                keyboard: false,
                                bgclose: false,
                                center: true
                            }).hide();

                            this.examInfo(this.adminid, this.password)

                        } else {
                            //relay in error msg haruulah
                            alert("relay server request failed")
                        }
                        // console.log(response.data)
                    }


                } catch (error) {
                    this.error = error.response.data.error
                    alert(this.error)
                }

            },
            // login(item) {
            //   UIkit.modal('#modal-adminlogin', {modal: false, keyboard: false, bgclose: false, center: true}).show();
            // },
            async prepare(item) {
                try {
                    console.log(item);
                    const response = await OrganizService.examPrepare({})
                    if (response.data.status === 200) {
                        //  let tmsres = JSON.parse(response.data.message)
                        alert("success");
                        // console.log(response.data)
                    }
                } catch (error) {
                    this.error = error.response.data.error
                    alert(this.error)
                }


            },
            async createExamnee() {
                try {

                    const response = await ExamService.createExamnee({

                        roomCode: this.examinfo.result.room_code,
                        examroomName: this.roominfo.result.examroom_name,
                        examoomNum: this.roominfo.result.examroom_num,
                        seasonFormCode: this.examinfo.result.season_form_code,
                        examName: this.roominfo.result.exam_name,
                        examStatus: this.roominfo.result.exam_status,
                        questionCount: this.examinfo.result.question_count,
                        seasonExamTotalTime: this.examinfo.result.season_exam_total_time + '',
                        examStartTimeCode: this.examinfo.result.exam_start_time_code + '',
                        examEndTimeCode: this.examinfo.result.exam_end_time_code + '',
                        count: this.examinfo.result.count
                    })

                    if (response.data.status === 200) {
                        // console.log(response.data)
                    }
                } catch (error) {
                    this.error = error.response.data.message
                    alert(error.response.data.message)
                }


            },
            async getExams() {
                try {

                    const response = await OrganizService.getExams({})

                    if (response.data.status === 200) {

                        let tmsres = JSON.parse(response.data.message)

                        this.exams = tmsres.result
                        // console.log(tmsres.result)
                        // /season_form_status 4-end(vr dvn harah towch enable) 3-ready(login bolomjtoi) 2 - prepare (exam beltgel ajil ywagdaj baigaa)

                        // season_form_code - ashiglaj shalgalt ogoh oyutnuudiin medeelliig awna awaad exam code -generate hiij table rvvgee hiine
                        // (http://localhost:8082/osce_war/app/exam/examineeinfo?seasonformcode=19)

                    }


                } catch (error) {
                    this.error = error.response.data.error
                }
            },
            // loadFeaturedData() {
            //     const datatype = 3
            //     AnswersService.getFeuturedDatas({datatype: datatype}).then(res => {
            //         const {data} = res
            //         this.faudios = data
            //         if (!this.faudios.length) {
            //             return
            //         }
            //     })
            // },
            slugify: function (value) {
                if (!value) return ''

                var slug = ''
                // Change to lower case
                var valueLower = value.toLowerCase()
                // Letter "e"
                slug = valueLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e')
                // Letter "a"
                slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a')
                // Letter "o"
                slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o')
                // Letter "u"
                slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u')
                // Letter "d"
                slug = slug.replace(/đ/gi, 'd')
                // Trim the last whitespace
                slug = slug.replace(/\s*$/g, '')
                // Change whitespace to "-"
                slug = slug.replace(/\s+/g, '_')

                return slug
            },
        }
    }
</script>

<style scoped>
    .border-blue {
        border: 1px solid #ccc !important;
        background:#fff;
        border-radius: 15px;
    }

    .border-bottom-grey {
        border-bottom: 1px solid #eee;
    }

    #logo {
        border-radius: 10px;
    }

    #verPS {
        border-radius: 25px;
        border-color: #01B0FF;
        border: 0.5px solid;
        color: black;
    }

    #vps:hover {
        opacity: 50%;
        border-color: rgb(4, 108, 156);
    }

    .iconcolor {
        color: #01B0FF;
    }

    #button {
        border-radius: 25px;
        border-width: 2px;
        border-color: #01B0FF;
        color: #01B0FF;
        background: white;
        border-top-right-radius: 25px;
    }

    #button:hover {
        background: #01B0FF;
        border: 2px solid #01B0FF;
        color: #ffffff;
    }
</style>
