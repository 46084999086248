import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import cart from './modules/cart'
import messages from './modules/messages'
import auth from './modules/auth'
import laoding from './modules/loading'
import * as actions from './actions'
Vue.use(Vuex)

export default new Vuex.Store({
  strict: false,
  plugins: [
    createPersistedState()
  ],
  actions,
  modules: {
    cart,
    messages,
    auth,
    laoding
  }
})
