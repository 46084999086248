import Vue from 'vue'
import Router from 'vue-router'

import NProgress from 'nprogress'
import store from '@/store/store'
import i18n from '../i18n'
import StudentHome from '@/components/Studentcmp/StudentHome'
import OrganizHome from '@/components/Organizcmp/OrganizHome'

const isAuthenticatedAdmin = (to, from, next) => {
    if (store.getters.isLoggedIn && (store.getters.currentUser.authorities[0].authority === 'ROLE_ADMIN'
        || store.getters.currentUser.authorities[0].authority === 'ROLE_SUPERADMIN'
        || store.getters.currentUser.authorities[0].authority === 'ROLE_PROFESSOR'
        || store.getters.currentUser.authorities[0].authority === 'ROLE_PROCTOR')) {
        next()
    }
    // else {
    //     next("/en/student")
    // }
}
const isAuthenticated = (to, from, next) => {
    if (store.getters.isLoggedIn) {
        next('/home')
    } else {
        next()
    }
}

Vue.use(Router)

const routes = [
    {
        path: '/',
        redirect: `/${i18n.locale}`,
    },
    {
        path: '/:lang',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: 'confirm-account',
                name: 'confirmaccount',
                component: () => import("@/components/ConfirmAccount"),
            },
            {
                path: 'register',
                name: 'register',
                component: () => import("@/components/Register.vue"),
                beforeEnter: isAuthenticated
            },
            {
                path: 'reset-password',
                name: 'reset-password',
                component: () => import("@/components/reset-password"),
                beforeEnter: isAuthenticated
            },

            {
                path: 'organization/register',
                name: 'organizregister',
                component: () => import("@/components/OrganizRegister.vue"),
                beforeEnter: isAuthenticated
            },
            {
                path: 'login',
                name: 'login',
                component: () => import("@/components/Login.vue"),
                beforeEnter: isAuthenticated
            },
            {
                path: '/',
                name: 'home',
                component: () => import("@/components/Homecmp/Home.vue")

            },
            {
                path: 'faq',
                name: 'faq',
                component: () => import("@/components/Homecmp/Faq")

            },
            {
                path: 'ContactUs',
                name: 'ContactUs',
                component: () => import("@/components/Homecmp/ContactUs")

            },
            {
                path: 'news',
                name: 'news',
                component: () => import("@/components/Homecmp/News")
            },
            {
                path: 'mediaintroduction',
                name: 'mediaintroduction',
                component: () => import("@/components/Homecmp/MediaIntroduction")
            },
            {
                path: 'ubtintroduction',
                name: 'ubtintroduction',
                component: () => import("@/components/Homecmp/Ubtintroduction")
            },
            {
                path: 'notfound',
                name: 'notfound',
                component: () => import("@/components/Homecmp/NotFound")
            },
            {
                path: 'student',
                name: 'student',
                meta: {requiresAuth: true},
                component: StudentHome
            },
            {
                path: 'student/examlist',
                name: 'studentexamlist',
                meta: {requiresAuth: true},
                component: () => import("@/components/Studentcmp/StudentExamList")

            },
            {
                path: 'student/StudentResulView',
                name: 'StudentResulView',
                meta: {requiresAuth: true},
                component: () => import("@/components/Studentcmp/StudentResulView")
            },
            {
                path: 'student/Student/Payment',
                name: 'StudentPayment',
                meta: {requiresAuth: true},
                component: () => import("@/components/Studentcmp/StudentPayment")
            },
            {
                path: 'student/Exams',
                name: 'StudentsExams',
                meta: {requiresAuth: true},
                component: () => import("@/components/Studentcmp/StudentsExams")

            },

            {
                path: 'student/examrequest',
                name: 'studentexamrequest',
                meta: {requiresAuth: true},
                component: () => import("@/components/Studentcmp/StudentExamRequest")
            },
            {
                path: 'student/OrganizationsDetails/:organizId',
                name: 'StudentOrganizationDetail',
                meta: {requiresAuth: true},
                component: () => import("@/components/Studentcmp/StudentOrganizationDetail")
            },
            {
                path: 'organization/OrganizationQuestionList',
                name: 'OrganizationQuestionList',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/OrganizationQuestionList")
            },
            {
                path: 'organization/OrganizationQuestionPurchased',
                name: 'OrganizationQuestionPurchased',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/OrganizationQuestionPurchased")
            },

            {
                path: 'student/Organizations/',
                name: 'StudentOrganizations',
                meta: {requiresAuth: true},
                component: () => import("@/components/Studentcmp/StudentOrganizations")

            },
            {
                path: 'student/Register/Exam',
                name: 'StudentRegisterExam',
                meta: {requiresAuth: true},
                component: () => import("@/components/Studentcmp/StudentRegisterExam")

            },
            {
                path: 'student/exam',
                name: 'stexam',
                component: () => import("@/components/Homecmp/Exam/StudentExamQuiz")

            },
            {
                path: 'organization/student/register/:id',
                name: 'studentregister',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/OrganizStudentRegister"),
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization/student/list',
                name: 'studentlist',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/OrganizStudentList"),
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization/student/requestlist',
                name: 'studentrequest',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/OrganizStudentRequest"),
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization/zipupload',
                name: 'zipupload',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/ZipUpload"),
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization/category/:pagetype',
                name: 'organizcategory',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/OrganizCategory"),
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization/Survey/Add',
                name: 'OrganizationSurveyAdd',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/OrganizationSurveyAdd"),
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization/Survey/Result/list',
                name: 'SurveyResultList',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/OrganizationSurveyResultList"),
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization/OrganizationExamRegister/:examId',
                name: 'OrganizationExamRegister',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/OrganizationExamRegister"),
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization/Organization/ExamResultView',
                name: 'OrganizationExamResultView',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/OrganizationExamResultView"),
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization/Organization/Examinee/ResultView/:examId',
                name: 'OrganizationExamineeResultView',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/OrganizationExamineeResultView"),
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization/Organization/Examinee/ResultView2/:examId/:groupId',
                name: 'OrganizationExamineeResultView2',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/OrganizationExamineeResultView2"),
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization/groupexammapping/:examId',
                name: 'ExamGroupMapping',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/StudentExamMapping"),
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization/Organization/Examinee/ResultGrid/:examId',
                name: 'OrganizationExamineeResultGrid',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/OrganizationExamineeResultGrid"),
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization/Organization/Examinee/ResultGrid2',
                name: 'OrganizationExamineeResultGrid2',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/OrganizationExamineeResultGrid2"),
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization/Organization/examadd/management/:examId',
                name: 'OrganizationExamManagement',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/OrganizationExamManagement"),
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization/quiz/list',
                name: 'quizlist',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/OrganizQuizList"),
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization/professor/list/:type',
                name: 'professorlist',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/OrganizProfessorList"),
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization/proctor/list/:type',
                name: 'proctorlist',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/OrganizProctorList"),
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization/professor/register',
                name: 'professorregister',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/OrganizProfessorRegister"),
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization/excelupload',
                name: 'excelupload',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/ExcelRegister"),
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization',
                name: 'organization',
                meta: {requiresAuth: true},
                component: OrganizHome,
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization/market',
                name: 'market',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/OrganizMarket"),
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization/OrganizationQuestion/:questionId',
                name: 'OrganizationQuestion',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/OrganizationQuestion"),
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization/Organization/ExamList',
                name: 'OrganizationExamList',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/OrganizationExamList"),
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization/Organization/ProcExamList',
                name: 'OrganizationProcExamList',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/OrganizationProcExamList"),
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization/Point/Charge',
                name: 'OrganizationPointCharge',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/OrganizationPointCharge"),
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization/Students/Examcodes/:examId',
                name: 'OrganizationStudentExamcode',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/OrganizationStudentExamcode"),
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization/questionmarket',
                name: 'questionmarket',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/OrganizMarketQuestionList"),
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization/questionmarket/t/:slug-:catid',
                name: 'qcategory',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/OrganizMarketQuestionList"),
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization/questionmarket/t/:slug-:catid/:slug2-:catid2',
                name: 'qcategorys',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/OrganizMarketQuestionList"),
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization/questionmarket/t/:slug-:catid/:slug2-:catid2/:slug3-:catid3',
                name: 'qcategoryss',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/OrganizMarketQuestionList"),
                beforeEnter: isAuthenticatedAdmin
            },


            {
                path: 'organization/datamarket',
                name: 'datamarket',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/OrganizMarketDataList"),
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization/datamarket/t/:slug-:catid',
                name: 'category',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/OrganizMarketDataList"),
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization/datamarket/t/:slug-:catid/:slug2-:catid2',
                name: 'categorys',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/OrganizMarketDataList"),
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization/datamarket/t/:slug-:catid/:slug2-:catid2/:slug3-:catid3',
                name: 'categoryss',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/OrganizMarketDataList"),
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization/cart',
                name: 'ocart',
                meta: {requiresAuth: true},
                component: () => import("@/components/Organizcmp/OrganizCart"),
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization/data/:answerId',
                name: 'answer',
                component: () => import("@/components/Organizcmp/OrganizMarketDataDetail"),
                meta: {requiresAuth: true},
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'organization/question/:questionId',
                name: 'question',
                component: () => import("@/components/Organizcmp/OrganizQuestionDetail"),
                meta: {requiresAuth: true},
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'account/myhistories',
                name: 'myhistory',
                component: () => import("@/components/GlobViews/MyHistory"),
                meta: {requiresAuth: true}
            },
            {
                path: 'account/myhistories/organization',
                name: 'myhistoryorganiz',
                component: () => import("@/components/GlobViews/MyOrganizHistory"),
                meta: {requiresAuth: true},
                beforeEnter: isAuthenticatedAdmin
            },
            {
                path: 'account/settings',
                name: 'mysettings',
                component: () => import("@/components/GlobViews/MySettings"),
                meta: {requiresAuth: true}
            },
            {
                path: 'account/evalution',
                name: 'myevalution',
                component: () => import("@/components/GlobViews/MyEvalution"),
                meta: {requiresAuth: true},
            },
            {
                path: 'account/myinfo',
                name: 'myinfo',
                component: () => import("@/components/GlobViews/MyPersonalInfo"),
                meta: {requiresAuth: true},
            },
            {
                path: '*',
                redirect: {name: 'home'}
            }
        ]
    }
]

// const scrollBehavior = (to, from, savedPosition) => {
//   if (savedPosition) {
//     return savedPosition
//   }

//   if (to.hash) {
//     return { selector: to.hash }
//   }

//   return { x: 0, y: 0 }
// }

const router = new Router({
    routes,
    mode: 'history'
})

router.beforeEach((to, from, next) => {
    if (!to.meta.requiresAuth) {
        return next()
    }
    if (!store.getters.isLoggedIn) {
        return next({
            path: '/login',
            query: {redirect: to.fullPath}
        })

    }

    next()
})
router.beforeResolve((to, from, next) => {
    if (to.name) {
        NProgress.start()
    }
    next()
})
router.afterEach(() => {
    NProgress.done()
})
export default router

