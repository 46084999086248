import Api from '@/services/Api'

const resource = '/exam';

export default {
  logs(params = {}) {
    return Api().get(`/mobile/actions`, {
      params: params
    })
  },
  getExamList(params = {}) {
    return Api().get(`${resource}/list/?size=12`, {
      params: params
    })
  },
  getProctorExamList(params = {}) {
    return Api().get(`${resource}/proctor/exams`, {
      params: params
    })
  },

  getExamDetails(id, params = {}) {
    return Api().get(`${resource}/${id}`,
        {
          params: params
        })
  },
  getExamQuestionsSee(params = {}) {
    return Api().get(`${resource}/questions`, {
      params: params
    })
  },
  resetUser(credentials) {
    return Api().post(`exam/finish/toggle`, credentials)
  },
  getExamineeResultDetail(credentials) {
    return Api().post(`webexam/teacher/studentdetail`, credentials)
  },
  questionResultDelete(credentials) {
    return Api().post(`${resource}/clean/result`, credentials)
  },
  examClean(credentials) {
    return Api().post(`${resource}/clean/allresult`, credentials)
  },
  getDepartment(params = {}){
    return Api().get(`${resource}/examinee/resultdepart`, {
      params: params
    })
  },
  getExamineeResultGrid(params = {}){
    return Api().get(`${resource}/examinee/resultgrid`, {
      params: params
    })
  },
  createExamQuestionAdd(params = {}){
    return Api().get(`${resource}/puted/question`, {
      params: params
    })
  },
  createExamTypeAdd(params = {}) {
    return Api().get(`${resource}/put/questions`, {
      params: params
    })
  },
  viewExamCodes(params = {}) {
    return Api().get(`${resource}/view/examcodes`, {
      params: params
    })
  },
  viewExamResults(params = {}) {
    return Api().get(`${resource}/result/list/?size=12`, {
      params: params
    })
  },
  ExamCodeSendOne(credentials) {
    return Api().post(`${resource}/examcode/send`, credentials)
  },
  ExamCodeSendAll(credentials) {
    return Api().post(`${resource}/examcode/send/all`, credentials)
  },
  variantSend(credentials) {
    return Api().post(`${resource}/student/putoption`, credentials)
  },
  studentResultZip(credentials) {
    return Api().post(`${resource}/result/put/zip`, credentials)
  },
  studentResultZipAll(credentials) {
    return Api().post(`${resource}/result/put/zipall`, credentials)
  },
  offlineData(credentials) {
    return Api().post(`${resource}/create/offlinedata`, credentials)
  },
  getExamStatusChange(credentials) {
    return Api().post(`${resource}/status/change`, credentials)
  },
  updateExam(credentials) {
    return Api().post(`${resource}/modify`, credentials)
  },
  deleteExam(credentials) {
    return Api().post(`${resource}/delete`, credentials)
  },
  deleteExamQuestionOptions(credentials) {
    return Api().post(`${resource}/delete/questions/option`, credentials)
  },
  extraTimeSet(credentials) {
    return Api().post(`/webexam/extratime/change `, credentials)
  },
  createExam(credentials) {
    return Api().post(`${resource}/create`, credentials)
  },
  attachSurvey(credentials) {
    return Api().post(`${resource}/attach/survey`, credentials)
  },
  unAttachSurvey(credentials) {
    return Api().post(`${resource}/unattach/survey`, credentials)
  },

  getExamCode(params = {}) {
    return Api().get(`/view/examcodes`, {
      params: params
    })
  },
  getExcelDownload(credentials) {
    return Api().post(`${resource}/create/excel/report`, credentials)
  },
  getFilesDownload(credentials) {
    return Api().post(`/webexam/result/download/mediafiles`, credentials)
  },
  getJsonDownload(credentials) {
    return Api().post(`${resource}/export/jsonresult`, credentials)
  },
  getFreeExamList(params = {}) {
    return Api().get(`${resource}/free/examlist`, {
      params: params
    })
  },
  getExamPutedQuestions(params = {}) {
    return Api().get(`${resource}/puted/questions`, {
      params: params
    })
  },

  putExamQuestions(credentials) {
    return Api().post(`${resource}/put/questions`, credentials)
  },

  deleteExamQuestions(credentials) {
    return Api().post(`${resource}/delete/questions`, credentials)
  },

  generateExam(credentials) {
    return Api().post(`${resource}/generate`, credentials)
  },
  deleteExamOption(credentials) {
    return Api().post(`${resource}/delete/questions/option`, credentials)
  },
  changeOrder(credentials) {
    return Api().post(`${resource}/change/order`, credentials)
  },

}
