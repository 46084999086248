import Api from '@/services/Api'

const resource = '/tms'
const resource2 = '/organization'
export default {

  getExams(credentials) {
    return Api().post(`${resource}/examlist`, credentials)
  },
  examstatusChange(credentials) {
    return Api().post(`${resource}/exam/status/change`, credentials)
  },
  userAdd(credentials) {
    return Api().post(`${resource2}/user/add`, credentials)
  },
  examPrepare(roundid) {
    return Api().get(`${resource}/exam/prepare/${roundid}`)
  },
  adminLogin(params = {}) {
    return Api().get(`${resource}/exam/admin/login`, {
      params: params
    })
  },
  examInfo(params = {}) {
    return Api().get(`${resource}/exam/examinfo`, {
      params: params
    })
  },
  getHomeData(params = {}) {
    return Api().get(`${resource2}/homedata`, {
      params: params
    })
  },
  getCalendarList(params = {}) {
    return Api().get(`${resource2}/calendar/list`, {
      params: params
    })
  },
  roomInfo(params = {}) {
    return Api().get(`${resource}/exam/roominfo`, {
      params: params
    })
  }
}
