import Vue from 'vue'
import App from './App'
import router from './router'
import {sync} from 'vuex-router-sync'
import store from '@/store/store'
import Panel from '@/components/globals/Panel'
import UIkit from 'uikit'
import i18n from './i18n'
import IdleVue from "idle-vue"
import Icons from 'uikit/dist/js/uikit-icons'
import VuePlyr from 'vue-plyr'
import VueApexCharts from 'vue-apexcharts'

import 'vue-plyr/dist/vue-plyr.css'

require('@/assets/css/uikit.css');
require('@/assets/css/style.css');

Vue.use(VuePlyr);
Vue.use(VueApexCharts);


UIkit.use(Icons);
Vue.config.productionTip = false;

Vue.prototype.$getCookie = function (cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

Vue.prototype.$setCookie = function (cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + encodeURIComponent(cvalue) + ";" + expires + ";path=/";
};

router.beforeEach((to, from, next) => {
    let language = to.params.lang;
    if (!language) {
        language = 'en-EN'
    }
    i18n.locale = language;
    next()
});

Vue.component('panel', Panel);

sync(store, router);

new Vue({
    el: '#app',
    router,
    render: h => h(App),
    store,
    i18n,
    template: '<App/>',
    components: {App}
});

const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 3000000, // 30 minute,
  startAtIdle: false
});

