export const setToken = ({ commit }, token) => {
  commit('setToken', token)
}
export const setUser = ({ commit }, user) => {
  commit('setUser', user)
}
export const updateCart = ({ commit }, { item, quantity, isAdd, type }) => {
  commit('UPDATE_CART', { item, quantity, isAdd, type })
  if (isAdd) {
    const messageObj = {
      message: `Add ${item.title} to cart successfully`,
      messageClass: 'success',
      autoClose: true
    }
    commit('ADD_MESSAGE', messageObj)
  }
}

export const removeItemInCart = ({ commit }, { item }) => {
  commit('REMOVE_CART_ITEM', { item })
}
