<template>
    <div class="Home">
        <page-header/>
        <div class="uk-container uk-padding-remove" style="min-height: 650px;">
            <section class="uk-section uk-padding-small">
                <div class="uk-flex uk-margin-bottom uk-visible@m">
                    <input v-model="exam.examName"
                           class="uk-input enterExamCode"
                           :placeholder="$t('additional.examname')"
                           type="text"/>
                    <!--          <select class="uk-select select-search" v-model="exam.status" @click="myexamlist()">-->
                    <!--            <option disabled value="">{{ $t("additional.status") }}</option>-->
                    <!--            <option :value="1">{{ $t("additional.ready") }}</option>-->
                    <!--            <option :value="2">{{ $t("additional.start") }}</option>-->
                    <!--            <option :value="3">{{ $t("additional.finish") }}</option>-->
                    <!--            <option :value="4">{{ $t("additional.end") }}</option>-->
                    <!--          </select>-->
                    <date-picker
                            v-model="exam.startDate"
                            type="date"
                            style="height: 46px !important; border-radius: 30px !important;"
                            class="datepicker"
                            :placeholder="$t('startDate')"
                    ></date-picker>
                    <date-picker
                            v-model="exam.endDate"
                            type="date"
                            style="height: 46px !important; border-radius: 30px !important;"
                            class="datepicker uk-margin-small-left"
                            :placeholder="$t('endDate')"
                    ></date-picker>
                    <div class="uk-grid-small uk-child-width-auto uk-grid uk-margin-small-left">
                        <input class="uk-checkbox" type="checkbox" v-model="exam.gived"
                               style="width: 30px; height: 30px; margin-top: 7px;">
                        <label style="color: #0e6dcd; font-size: 20px; margin-right: 30px; margin-top: 8px;">
                            {{ $t("additional.taken") }}
                        </label>
                        <input class="uk-checkbox" type="checkbox" v-model="exam.realExam"
                               style="width: 30px; height: 30px; margin-top: 7px;">
                        <label style="color: #0e6dcd; font-size: 20px; margin-top: 8px;">
                            {{ $t("additional.rExam") }}
                        </label>
                    </div>
                    <span uk-icon="search" class="searchIcon" @click="myexamlist(currentPage)"
                          style="cursor: pointer"></span>
                    <span uk-icon="refresh" class="searchIcon" @click="reload()" style="cursor: pointer"></span>
                </div>
                <div class="uk-width-1-1 uk-flex uk-flex-right uk-margin-remove-left" uk-grid>
                    <div class="uk-grid-small" uk-grid>
                        <div class="uk-width-2-3@m">
                            <input v-model="inputval" style="width: 100%"
                                   class="uk-input enterExamCode"
                                   :placeholder="$t('additional.eCode')"
                                   type="text"/>
                        </div>
                        <div class="uk-width-1-3@m">
                            <button type="button" v-on:click="ExamCodeConfirm(inputval)"
                                    style="border-radius: 30px; border: 1px solid #0d9df9; width: 100%; padding: 4px 10px 4px 10px;"
                                    class="uk-button uk-button-result uk-text-capitalize">
                                {{ $t('studentHome.enrollment') }}
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <section class="uk-section uk-padding-remove">
                <div class="uk-card uk-card-default uk-padding-remove" style="border-radius: 15px">
                    <table
                            class="uk-table uk-table-striped uk-margin-remove uk-text-center
              uk-table-divider uk-table-responsive uk-visible@m">
                        <thead>
                        <tr>
                            <th style="border-top-left-radius: 15px">No</th>
                            <th>{{ $t("studentResultView.organiz") }}</th>
                            <th style="min-width: 80px;">{{ $t("studentResultView.date") }}</th>
                            <th style="min-width: 120px;">{{ $t("studentResultView.eName") }}</th>
                            <th>{{ $t("studentResultView.eTime") }}</th>
                            <th>{{ $t("studentResultView.variant") }}</th>
                            <th>{{ $t("studentResultView.qCount") }}</th>
                            <th>{{ $t("studentResultView.eScore") }}</th>
                            <th>{{ $t("studentResultView.yScore") }}</th>
                            <th style="border-top-right-radius: 15px; min-width: 120px;">...</th>
                        </tr>
                        </thead>
                        <tbody v-if="myexams.length > 0">
                        <template v-for="(exp,index) in myexams">
                            <tr :key="index" class="uk-text-center">
                                <td v-bind:rowspan="exp.variantResult.length">{{ index + 1 }}</td>
                                <td v-bind:rowspan="exp.variantResult.length">
                                    <img v-if="exp.organizLogo.includes('http') || exp.organizLogo.includes('https')"
                                         :src="exp.organizLogo" alt="Logo organization" style="width:39px !important;">
                                    <img v-else :src="'/uploadingDir/organizicons/' + exp.organizLogo"
                                         alt="Logo organization" style="width:39px !important;">
                                </td>
                                <td v-bind:rowspan="exp.variantResult.length">
                                    <span>{{ exp.examSdate | moment }}</span>
                                    <span class="uk-text-bold" style="color: rgb(1, 176, 255)">~</span><br>
                                    <span>{{ exp.examEdate | moment }}</span>
                                </td>
                                <td class="uk-text-left uk-text-capitalize"
                                    v-bind:rowspan="exp.variantResult.length">
                                    <span>{{ exp.examName }}</span>
                                    <span class="uk-margin-small-left uk-text-bold"
                                          style="color:rgb(1, 176, 255)">
                    ({{ exp.examCode }})
                  </span>
                                </td>
                                <td v-bind:rowspan="exp.variantResult.length">
                                    {{ exp.examTime }} {{ $t("additional.minute") }}
                                </td>
                                <template v-if="exp.variantResult.length > 0">
                                    <td>{{ exp.variantResult[0].variant }}</td>
                                    <td>{{ exp.variantResult[0].totalQuestion }}</td>
                                    <td>{{ exp.variantResult[0].totalScore }}</td>
                                    <td>
                                        <div v-if="exp.examStatus != 'start'">
                      <span v-if="exp.scoreSee != 'true'">
                        ...
                      </span>
                                            <span v-else>
                        {{ exp.variantResult[0].examineeScore }}
                      </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div v-if="exp.examStatus == 'start' && exp.isEnded == false">
                                            <!--                      <div v-if="exp.realExam == 1">-->
                                            <div v-if="exp.faceLogin == 1">
                                                <button type="button" class="btn-exam-start"
                                                        @click="faceLogin(exp, exp.variantResult[0].variant)"
                                                        v-if="exp.variantResult[0].givedCount == 0">
                            <span>
                              {{ $t("additional.start") }}
                            </span>
                                                </button>
                                                <div v-else>
                                                    <button type="button" class="btn-exam-disabled">
                                                        {{ $t("additional.start") }}
                                                    </button>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <div v-if="exp.variantResult[0].givedCount == 0">
                                                    <button type="button" class="btn-exam-start"
                                                            v-if="exp.recording == 'false'"
                                                            @click="takeExamUrl(exp.examId, exp.examCode, exp.variantResult[0].variant, exp.whale, exp.grpId, exp.meet)">
                                                        {{ $t("additional.start") }}
                                                    </button>
                                                    <button type="button" class="btn-exam-start" v-else
                                                            @click="askRecording(exp, exp.variantResult[0].variant)">
                                                        {{ $t("additional.start") }}
                                                    </button>
                                                </div>
                                                <div v-else>
                                                    <button type="button" class="btn-exam-disabled">
                                                        {{ $t("additional.start") }}
                                                    </button>
                                                </div>
                                            </div>
                                            <!--                      </div>-->
                                            <!--                      <div v-else>-->
                                            <!--                        <button type="button"-->
                                            <!--                                @click="takeExamUrl(exp.examId, exp.examCode,exp.variantResult[0].variant, exp.whale, exp.grpId, exp.meet)"-->
                                            <!--                                class="btn-exam-start"-->
                                            <!--                                v-if="exp.variantResult[0].givedCount == 0">-->
                                            <!--                          {{ $t("additional.start") }}-->
                                            <!--                        </button>-->
                                            <!--                        <button type="button"-->
                                            <!--                                @click="takeExamUrl(exp.examId, exp.examCode, exp.variantResult[0].variant, exp.whale, exp.grpId, exp.meet)"-->
                                            <!--                                class="btn-exam-start"-->
                                            <!--                                v-else-if="exp.variantResult[0].givedCount > 0 && exp.reTake != 'no'">-->
                                            <!--                          {{ $t("additional.reTake") }}-->
                                            <!--                        </button>-->
                                            <!--                        <button type="button" class="btn-exam-disabled" @click="notyetfinish()"-->
                                            <!--                                v-else>-->
                                            <!--                          {{ $t("additional.start") }}-->
                                            <!--                        </button>-->
                                            <!--                      </div>-->
                                        </div>
                                        <div v-else>
                                            <button type="button" class="btn-exam-disabled" @click="notyetfinish()">
                                                {{ $t("additional.start") }}
                                            </button>
                                        </div>
                                        <button type="button" class="btn-exam-result"
                                                v-show="exp.variantResult[0].givedCount > 0 && exp.resultSeeType == 'true' "
                                                @click="examReviewUrl('exam_review',exp.examId, exp.examCode, exp.variantResult[0].variant)">
                                            {{ $t("additional.result") }}
                                        </button>
                                        <button type="button" class="btn-exam-disabled"
                                                v-show="exp.variantResult[0].givedCount == 0 && exp.resultSeeType == 'true'"
                                                style="margin-top: 5px;" @click="noResultHere()">
                                            {{ $t("additional.result") }}
                                        </button>
                                    </td>
                                </template>
                            </tr>
                            <template v-if="exp.variantResult.length > 1">
                                <template v-for="(data, index) in exp.variantResult">
                                    <tr :key="data.id" v-if="index > 0">
                                        <td>{{ data.variant }}</td>
                                        <td>{{ data.totalQuestion }}</td>
                                        <td>{{ data.totalScore }}</td>
                                        <td>
                      <span v-if="exp.scoreSee != 'true'">
                      ...
                      </span>
                                            <span v-else>
                        {{ data.examineeScore }}
                      </span>
                                        </td>
                                        <td>
                                            <div v-if="exp.examStatus == 'start' && exp.isEnded == false">
                                                <!--                        <div v-if="exp.realExam == 1">-->
                                                <div v-if="exp.faceLogin == 1">
                                                    <button type="button" class="btn-exam-start"
                                                            @click="faceLogin(exp, data.variant)"
                                                            v-if="data.givedCount == 0">
                                <span>
                                  {{ $t("additional.start") }}
                                </span>
                                                    </button>
                                                    <div v-else>
                                                        <button type="button" class="btn-exam-disabled">
                                                            {{ $t("additional.start") }}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <div v-if="data.givedCount == 0">
                                                        <button type="button" class="btn-exam-start"
                                                                v-if="exp.recording == 'false'"
                                                                @click="takeExamUrl(exp.examId, exp.examCode, data.variant,exp.whale, exp.grpId, exp.meet)">
                                  <span>
                                    {{ $t("additional.start") }}
                                  </span>
                                                        </button>
                                                        <button type="button" class="btn-exam-start" v-else
                                                                @click="askRecording(exp, data.variant)">
                                    <span>
                                      {{ $t("additional.start") }}
                                    </span>
                                                        </button>
                                                    </div>
                                                    <div v-else>
                                                        <button type="button" class="btn-exam-disabled">
                                                            {{ $t("additional.start") }}
                                                        </button>
                                                    </div>
                                                </div>
                                                <!--                        </div>-->
                                                <!--                        <div v-else>-->
                                                <!--                          <button type="button" @click="takeExamUrl(exp.examId, exp.examCode,-->
                                                <!--                          data.variant, exp.whale, exp.grpId, exp.meet)"-->
                                                <!--                                  class="btn-exam-start"-->
                                                <!--                                  v-if="data.givedCount == 0">-->
                                                <!--                              <span>-->
                                                <!--                                {{ $t("additional.start") }}-->
                                                <!--                              </span>-->
                                                <!--                          </button>-->
                                                <!--                          <button type="button"-->
                                                <!--                                  @click="takeExamUrl(exp.examId, exp.examCode, data.variant,-->
                                                <!--                                  exp.whale, exp.grpId, exp.meet)"-->
                                                <!--                                  class="btn-exam-start"-->
                                                <!--                                  v-else-if="data.givedCount > 0 && exp.reTake != 'no'">-->
                                                <!--                              <span>-->
                                                <!--                                {{ $t("additional.reTake") }}-->
                                                <!--                              </span>-->
                                                <!--                          </button>-->
                                                <!--                          <button type="button" class="btn-exam-disabled"-->
                                                <!--                                  @click="notyetfinish()" v-else>-->
                                                <!--                            {{ $t("additional.start") }}-->
                                                <!--                          </button>-->
                                                <!--                        </div>-->
                                            </div>
                                            <div v-else>
                                                <button type="button" class="btn-exam-disabled" @click="notyetfinish()">
                                                    {{ $t("additional.start") }}
                                                </button>
                                            </div>
                                            <button type="button" class="btn-exam-result"
                                                    v-show="data.givedCount > 0 && exp.resultSeeType == 'true' "
                                                    @click="examReviewUrl('exam_review',exp.examId, exp.examCode, data.variant)">
                                                {{ $t("additional.result") }}
                                            </button>
                                            <button type="button" class="btn-exam-disabled"
                                                    v-show="data.givedCount == 0 && exp.resultSeeType == 'true'"
                                                    style="margin-top: 5px;" @click="noResultHere()">
                                                {{ $t("additional.result") }}
                                            </button>
                                        </td>
                                    </tr>
                                </template>
                            </template>
                        </template>
                        </tbody>
                        <tbody v-else>
                        <tr>
                            <th colspan="12">Empty</th>
                        </tr>
                        </tbody>
                    </table>
                    <table class="uk-hidden@m uk-table uk-table-striped uk-table-divider uk-margin-remove">
                        <template v-for="(exp,expindex) in myexams">
                            <tbody v-if="myexams.length > 0 && exp.variantResult.length > 0" :key="expindex">
                            <tr>
                                <th class="tabletitle" colspan="2">{{ $t("studentExams.eName") }}</th>
                                <th class="tabletitle" colspan="2">{{ $t("studentResultView.date") }}</th>
                            </tr>
                            <tr>
                                <td colspan="2">{{ exp.examName }}</td>
                                <td>{{ exp.examSdate | moment }}</td>
                                <td>{{ exp.examEdate | moment }}</td>
                            </tr>
                            <tr>
                                <td class="tabletitlesub">{{ $t("studentResultView.qCount") }}</td>
                                <td class="tabletitlesub">{{ $t("studentResultView.eScore") }}</td>
                                <td class="tabletitlesub">{{ $t("studentResultView.yScore") }}</td>
                                <td class="tabletitlesub">...</td>
                            </tr>
                            <tr>
                                <td>{{ exp.variantResult[0].totalQuestion }}</td>
                                <td>{{ exp.variantResult[0].totalScore }}</td>
                                <td>
                  <span v-if="exp.scoreSee != 'true'">
                    ...
                  </span>
                                    <span v-else>
                    {{ exp.variantResult[0].examineeScore }}
                  </span>
                                </td>
                                <td>
                                    <div v-if="exp.examStatus == 'start' && exp.isEnded == false">
                                        <!--                    <div v-if="exp.realExam == 1">-->
                                        <div v-if="exp.faceLogin == 1">
                                            <button type="button" class="btn-exam-start"
                                                    v-if="exp.variantResult[0].givedCount == 0"
                                                    @click="faceLogin(exp, exp.variantResult[0].variant)">
                          <span>
                            {{ $t("additional.start") }}
                          </span>
                                            </button>
                                            <div v-else>
                                                <button type="button" class="btn-exam-disabled">
                                                    {{ $t("additional.start") }}
                                                </button>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <div v-if="exp.variantResult[0].givedCount == 0">
                                                <button type="button" class="btn-exam-start"
                                                        v-if="exp.recording == 'false'"
                                                        @click="takeExamUrl(exp.examId, exp.examCode, exp.variantResult[0].variant, exp.whale, exp.grpId, exp.meet)">
                            <span>
                              {{ $t("additional.start") }}
                            </span>
                                                </button>
                                                <button type="button" class="btn-exam-start" v-else
                                                        @click="askRecording(exp, exp.variantResult[0].variant)">
                            <span>
                              {{ $t("additional.start") }}
                            </span>
                                                </button>
                                            </div>
                                            <div v-else>
                                                <button type="button" class="btn-exam-disabled">
                                                    {{ $t("additional.start") }}
                                                </button>
                                            </div>
                                        </div>
                                        <!--                    </div>-->
                                        <!--                    <div v-else>-->
                                        <!--                      <button type="button" class="btn-exam-start"-->
                                        <!--                              v-if="exp.variantResult[0].givedCount == 0"-->
                                        <!--                              @click="takeExamUrl(exp.examId, exp.examCode, exp.variantResult[0].variant, exp.whale, exp.grpId, exp.meet)">-->
                                        <!--                          <span>-->
                                        <!--                            {{ $t("additional.start") }}-->
                                        <!--                          </span>-->
                                        <!--                      </button>-->
                                        <!--                      <button type="button" class="btn-exam-start"-->
                                        <!--                              v-else-if="exp.variantResult[0].givedCount > 0 && exp.reTake != 'no'"-->
                                        <!--                              @click="takeExamUrl(exp.examId, exp.examCode, exp.variantResult[0].variant, exp.whale, exp.grpId, exp.meet)">-->
                                        <!--                          <span>-->
                                        <!--                            {{ $t("additional.reTake") }}-->
                                        <!--                          </span>-->
                                        <!--                      </button>-->
                                        <!--                      <button type="button" class="btn-exam-disabled" @click="notyetfinish()"-->
                                        <!--                              v-else>-->
                                        <!--                        {{ $t("additional.start") }}-->
                                        <!--                      </button>-->
                                        <!--                    </div>-->
                                    </div>
                                    <div v-else>
                                        <button type="button" class="btn-exam-disabled" @click="notyetfinish()">
                                            {{ $t("additional.start") }}
                                        </button>
                                    </div>
                                    <button type="button" class="btn-exam-result"
                                            v-show="exp.variantResult[0].givedCount > 0 && exp.resultSeeType == 'true' "
                                            @click="examReviewUrl('exam_review',exp.examId, exp.examCode, exp.variantResult[0].variant)">
                                        {{ $t("additional.result") }}
                                    </button>
                                    <button type="button" class="btn-exam-disabled"
                                            v-show="exp.variantResult[0].givedCount == 0 && exp.resultSeeType == 'true'"
                                            style="margin-top: 5px;" @click="noResultHere()">
                                        {{ $t("additional.result") }}
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </table>
                </div>
                <!--        <div class="uk-width-1-1 uk-text-center  uk-margin-top">-->
                <!--          <h4 class="uk-text-danger" style="margin-top: 30px !important;">-->
                <!--            <b>사전 점검테스트 미완료자의 경우, 시험에 응시하실 수 없습니다.</b>-->
                <!--          </h4>-->
                <!--        </div>-->
            </section>

            <section class="uk-section uk-padding-remove ">
                <div class="uk-container">
                    <div class="uk-card-footer uk-text-center">
                        <div class="uk-flex uk-flex-center">
                            <v-pagination
                                    v-model="currentPage"
                                    :page-count="pages"
                                    :classes="uikitClasses"
                                    :labels="Labels"
                                    @change="onChange"
                            ></v-pagination>
                        </div>
                    </div>
                </div>
            </section>
            <div id="retake" uk-modal>
                <div class="uk-modal-dialog">
                    <button class="uk-modal-close-default" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-center"
                            style="font-size: 21px; font-weight: 700;">Retaked exam list</h2>
                    </div>
                    <div class="uk-modal-body uk-text-center" style="padding-left: 10px;">
                        <table class="uk-table uk-table-striped">
                            <thead>
                            <tr>
                                <th class="uk-text-center">Round</th>
                                <th class="uk-text-center">Date</th>
                                <th class="uk-text-center">Result id</th>
                                <th class="uk-text-center">Score</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item,indexss) in reTakeExamDetail" :key="indexss">
                                <td>{{ item.round }}</td>
                                <td>{{ item.date | moment }}</td>
                                <td>{{ item.resId }}</td>
                                <td>{{ item.trueCount }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="uk-modal-footer uk-text-right">
                        <button type="button" class="uk-button uk-button-default uk-button-small uk-modal-close">
                            {{ $t("close") }}
                        </button>
                    </div>
                </div>
            </div>
            <!--      modal begin-->
            <div id="qrmodal" uk-modal>
                <div class="uk-modal-dialog">
                    <button class="uk-modal-close-default" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-center">QR code scan</h2>
                    </div>
                    <div class="uk-modal-body">
                        <div class="uk-text-center" v-show="getStatus == 2 || getStatus ==3">
                            <h5 class="uk-margin-small">
                                Exam name : <span
                                    class="uk-text-success uk-margin-small-left"> {{ examDetail.examName }}</span>
                            </h5>
                            <h5 class="uk-margin-small">
                                Start date : <span class=" uk-margin-small-left">{{ lifeStartDate|moment }}</span>
                            </h5>
                        </div>
                        <div class="uk-text-center uk-margin" v-show="getStatus == 2 || getStatus ==3">
                            <div v-if="qrImagePath != ''">
                                <img :src="'/uploadingDir/qrcode/' + qrImagePath" style="width: 240px"/>
                            </div>
                        </div>

                        <div class="uk-text-center">
                            <h5 class="uk-margin-top">
                                QR ID : <span class="uk-text-success uk-margin-small-left">{{ qrSendID }}</span>
                            </h5>
                            <h5 class="uk-margin-small">
                                QR CODE : <span class="uk-text-success uk-margin-small-left">{{ qrSendSecret }}</span>
                            </h5>
                            <h5 class="uk-margin-small">
                                <!--                 v-show="getStatus != 2 || getStatus !=3"-->
                                Exam Status : <span
                                    class="uk-text-success uk-margin-small-left">{{ qrExamStatus }}</span>
                            </h5>
                        </div>
                    </div>
                    <div class="uk-modal-footer uk-text-center">
                        <button type="button" class="uk-button uk-button-primary uk-modal-close"
                                v-show="getStatus > 2"
                                @click="takeExamUrl(exp.examId, exp.examCode, exp.variantResult[0].variant, exp.whale, exp.grpId, exp.meet)">
                            Start
                        </button>
                    </div>
                </div>
            </div>
            <div id="showQR" uk-modal>
                <div class="uk-modal-dialog">
                    <button class="uk-modal-close-default" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-center">QR code scan</h2>
                    </div>
                    <div class="uk-modal-body uk-text-center">
                        <div ref="qrcode" style="width:300px; height:300px; margin-left: 23%;"></div>
                    </div>
                    <div class="uk-modal-footer uk-text-center">
                        <button type="button" class="uk-button uk-button-primary uk-modal-close"
                                @click="callExam()">
                            Start
                        </button>
                    </div>
                </div>
            </div>
            <div id="showFirst" uk-modal>
                <div class="uk-modal-dialog">
                    <button class="uk-modal-close-default" uk-close></button>
                    <div class="uk-modal-header" style="background: #C0BFBFFF">
                        <h3 class="uk-text-center">
                            사전 모의테스트 미 완료자는 시험에 <br>응시할 수 없습니다
                        </h3>
                    </div>
                    <div class="uk-modal-body uk-text-left">
                        <div>
                            <h4 class="uk-text-center" style="font-weight: 800">
                                응시자 사전안내 가이드를 따라 2022.05.16.(월) 10:00 ~ 2022.05.20.(금) 21:00 까지 사전 모의테스트를 완료해야지만 시험 응시
                                가능
                            </h4>
                        </div>
                        <div class="uk-margin-small">
                            <h4>
                                ※ 모의테스트 진행하지 않을 경우, 05.21.(토) 시험 응시가 불가하며 이에 따른 시험 취소 및 환불조치는 되지 않는 점 양지 바랍니다.
                            </h4>
                        </div>
                        <!--            <div>-->
                        <!--              <h4> ◎ 작업 예상 시간: <br> 5월 19일(목) PM 17:00 ~ 5월 20일(금) 10:00</h4>-->
                        <!--            </div>-->
                        <!--            <div class="uk-margin-small">-->
                        <!--              <h4>이용에 불편을 드려 죄송합니다.<br>-->
                        <!--                더 나은 서비스로 찾아 뵙겠습니다-->
                        <!--              </h4>-->
                        <!--            </div>-->
                    </div>
                    <div class="uk-modal-footer uk-text-center uk-flex">
                        <div class="uk-flex">
                            <input type="checkbox" v-model="shModel" class="uk-margin-right"
                                   style="width: 25px; height: 25px;" @change="dontShow()">
                            <h4 class="uk-margin-remove">다시 보지 않기</h4>
                        </div>
                        <button type="button" class="uk-button uk-button-primary uk-modal-close"
                                style="position: absolute; right: 38px;">
                            닫기
                        </button>
                    </div>
                </div>
            </div>

            <!--      modal end-->
            <div id="recordingAsk" uk-modal>
                <div class="uk-modal-dialog">
                    <button class="uk-modal-close-default" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-text-center">
                            {{ $t('studentHome.notice') }}
                        </h2>
                    </div>
                    <div class="uk-modal-body uk-text-center">
                        <p v-html='$t("studentHome.recordingAsk")'></p>
                    </div>
                    <div class="uk-modal-footer uk-text-center">
                        <button type="button" class="uk-button uk-button-primary uk-modal-close" @click="qrAlert()">
                            {{ $t('studentHome.recordingQR') }}
                        </button>
                    </div>
                </div>
            </div>

            <div v-show="frameinfo.display" class="modal">
                <div class="modal-content" style="position: relative">
                    <span class="close" @click="frameclose"
                          style="position: absolute;top: 0;right: 15px;">&times;</span>
                    <div style="min-height: 500px" class="embed-responsive embed-responsive-16by9 z-depth-1-half">
                        <iframe id="videoframe" src=""
                                frameBorder="0"
                                width="900"
                                height="900"
                                allow="camera; microphone *">
                        </iframe>
                    </div>
                </div>
            </div>
        </div>
        <page-footer/>
    </div>
</template>
<script scoped>
    import StudentService from "../../services/StudentService";
    import vPagination from "@/components/globals/vue-plain-pagination"
    import moment from "moment";
    import DatePicker from "vue2-datepicker"
    import 'vue2-datepicker/index.css';
    import UIkit from "uikit";
    import store from '@/store/store'
    import {mapGetters} from "vuex";
    import axios from 'axios'

    const PageHeader = () => import("./StudentHeaderNew.vue");
    const PageFooter = () => import("../Organizcmp/OrganizFooter.vue");
    export default {
        name: 'Home',
        components: {
            vPagination,
            PageHeader,
            PageFooter,
            DatePicker
        },
        computed: {
            ...mapGetters(['isLoggedIn', 'currentUser'])
        },
        data() {
            return {
                frameinfo: {
                    display: false,
                    url: ""
                },
                polling: false,
                error: null,
                exams: null,
                exam: {
                    examName: '',
                    date: '',
                    startDate: '',
                    endDate: '',
                    realExam: 0,
                    status: '',
                    gived: 0,
                    todayExam: '',
                    tomorrowExam: '',
                    ungived: ''
                },
                latestexams: [],
                myOrganizCount: null,
                organizs: [],
                myorganizs: [],
                myExamCount: null,
                userexams: [],
                myexams: [],
                items: null,
                examId: 0,
                CurrentExam: '',
                examcode: '',
                inputval: '',
                myallexams: '',
                organizId: 0,
                count: 0,
                currentDate: '',
                // localPath: 'http://localhost:8084/ubtexam/user/exam/s',
                localPath: '/ubtexam/user/exam/s',
                currentPage: 1,
                pages: 0,
                uikitClasses: {
                    ul: "uk-pagination",
                    li: "",
                    liActive: "uk-active",
                    liDisable: "uk-disabled",
                    button: "page-link"
                },
                Labels: {
                    first: "<span uk-icon='chevron-double-left'; ratio = '1.2'></span>",
                    prev: "<span uk-pagination-previous></span>",
                    next: "<span uk-pagination-next></span>",
                    last: "<span uk-icon='chevron-double-right'; ratio = '1.2'></span>"
                },
                reTakeExamDetail: [],
                qrSendID: '',
                qrSendSecret: '',
                getStatus: 0,
                qrImagePath: '',
                examDetail: {},
                qrExamStatus: '',
                modalBody: '',
                lifeStartDate: '',
                currentVariant: '',
                examineeLoginId: '',
                organizationinputval: '',
                shModel: false,
                waitTime: 0,
                interval: null,
                waExam: {
                    eId: '',
                    eCd: '',
                    vari: '',
                    whUse: '',
                    grpId: '',
                    meet: null
                },
                isLoading: false
            }
        },
        created() {
            // this.StudentHomeData()
            // window.location.href =  '/ubtexam/student_home'
            if (window.location.host.includes('kr')) {
                this.$i18n.locale = 'kr'
            }
        },
        filters: {
            moment: function (date) {
                return moment(date).format('YYYY.MM.DD HH:mm')
            }
        },
        methods: {
            dontShow() {
                localStorage.setItem('notice', true)
            },
            frameclose() {
                document.getElementById('videoframe').src = '';
                this.frameinfo.display = false;
            },
            itemclick(Item) {
                this.CurrentExam = Item
            },
            faceLogin: function (item, variantQr) {
                this.examDetail = item;
                this.currentVariant = variantQr;

                const constraints = (window.constraints = {
                    audio: false,
                    video: true
                });
                navigator.mediaDevices
                    .getUserMedia(constraints)
                    .then(stream => {
                        console.log("stream" + stream)
                        _this.callExam();
                        store.commit('setloading', false);
                    })
                    .catch(error => {
                        store.commit('setloading', false);
                        // clearInterval(_this.interval)
                        // _this.waitTime = 0
                        alert("you need to allow your camera")
                        console.log("errorCallback орж ирсэн ", error);
                    });
                document.getElementById('videoframe').src = "https://facere-eps.ublrandd.com.np/epsUBTAPI/"
                    + item.examId + "/" + this.currentUser.id + "/?iframeGET=1";
                document.getElementById('videoframe').allow = "camera; microphone *";
                this.frameinfo.display = true;
                var _this = this;
                window.addEventListener("message", (e) => {
                    if (e.data === 'FaceReSuccess') {
                        // if (_this.getStatus != '3') {
                        _this.frameclose();
                        if (item.recording == 'true') {
                            var modal = UIkit.modal("#recordingAsk");
                            modal.show();
                        } else {
                            this.takeExamUrl(item.examId, item.examCode, variantQr, item.whale, item.grpId, item.meet)
                        }
                        _this.frameclose();
                        _this.frameinfo.display = false;
                    }
                }, false);
            },
            askRecording(item, variantQr) {
                this.examDetail = item;
                this.currentVariant = variantQr;
                var modal = UIkit.modal("#recordingAsk");
                modal.show();
            },
            qrAlert() {
                try {
                    this.lifeStartDate = this.examDetail.examSdate;
                    this.examCodeQR = this.examDetail.examCode;

                    // this.frameclose();
                    let formData = new FormData();

                    let examTimeSend = this.examDetail.examTime;
                    examTimeSend = examTimeSend * 60;

                    formData.append('ExamName', this.examDetail.examName);
                    formData.append('ExamTime', examTimeSend);
                    formData.append('VideoQuality', 0.05);
                    formData.append('StudentName', this.currentUser.username);
                    formData.append('StudentID', this.currentUser.id);
                    formData.append('ExamID', this.examDetail.examId);
                    formData.append('IfSaveStillImages', 'False');
                    formData.append('SaveStillImagIntervalSec', '10');
                    var _this = this;
                    axios.post('https://nsdai.org/GetExamDetailForUBT', formData).then(function (response) {
                        _this.requestRes = response.data;
                        _this.getStatus = response.data.exam.status;
                        _this.qrSendID = response.data.exam.id;
                        _this.qrSendSecret = response.data.exam.Secret;
                        _this.sendingQR(response.data);
                        if (_this.getStatus <= 2) {
                            _this.polling = setInterval(function () {
                                _this.statusGetForm(response.data)
                            }, 2000);
                        }
                    });
                } catch (error) {
                    console.log(error)
                }
            },
            async sendingQR(item) {
                try {
                    let send = item.exam;
                    this.qrExamStatus = item.statusText;

                    let sendingItem = {
                        id: send.id,
                        ExamName: send.ExamName,
                        ExamTime: send.ExamTime,
                        Quality: send.VideoQuality,
                        StudentName: send.StudentName,
                        StudentId: send.StudentID,
                        ExamId: send.ExamID,
                        status: send.status,
                        Secret: send.Secret,
                        ExamCode: this.examCodeQR,
                        Variant: this.currentVariant
                    };
                    const response = await StudentService.sendingQRfiles(sendingItem);
                    if (response.data.status == 200) {
                        if (response.data.success == true) {
                            this.qrImagePath = response.data.result.qrpath;
                            var modal = UIkit.modal("#qrmodal");
                            modal.show()
                        }
                    }
                } catch (error) {
                    console.log(error)
                }
            },
            statusGetForm(item) {
                try {
                    let send = item.exam;
                    let formData = new FormData();

                    formData.append('pk', send.id);
                    formData.append('secret', send.Secret);

                    var _this = this;

                    axios.post('https://nsdai.org/GetStatusFromUBT', formData).then(function (response) {

                        _this.getStatus = response.data.status;

                        if (_this.getStatus > 2) {
                            clearInterval(_this.polling)
                        }
                    });
                } catch (error) {
                    console.log(error)
                }
            },
            examReviewUrl(link, examId, examCode, variant) {
                store.commit('setloading', true);
                this.$setCookie('utoken', store.getters.getToken);
                this.$setCookie('studentid', store.getters.currentUser.id);
                this.$setCookie('urole', store.getters.currentUser.authorities[0].authority);
                this.$setCookie('uorganizId', store.getters.currentUser.organizs[0].organizId);

                window.location.href = this.localPath + "/" + link + "?lang=" +
                    this.$i18n.locale + "&examId=" + examId + "&examCode=" + examCode +
                    "&variant=" + variant
            },
            takeExamUrl(examId, examCode, variant, whUse, grpId, meet) {
                store.commit('setloading', true);

                this.waExam.eId = examId
                this.waExam.eCd = examCode
                this.waExam.vari = variant
                this.waExam.whUse = whUse
                this.waExam.grpId = grpId
                this.waExam.meet = meet

                if (grpId != '' || grpId != null) {
                    /* eslint-disable */
                    makeCode("https://nsdai.org/UBTMeetHeadPos-Student?RoomId="
                        + grpId + "_" + this.waExam.eId + "_tab" + "&ID=" +
                        this.currentUser.username, this.$refs.qrcode);
                }
                this.$setCookie('utoken', store.getters.getToken);
                this.$setCookie('studentid', store.getters.currentUser.id);
                this.$setCookie('loginId', store.getters.currentUser.username);
                this.$setCookie('urole', store.getters.currentUser.authorities[0].authority);
                this.$setCookie('uorganizId', store.getters.currentUser.organizs[0].organizId);
                // this.$setCookie('uImage', store.getters.currentUser.image);

                if (meet == 1) {
                    const constraints = (window.constraints = {
                        audio: false,
                        video: true
                    });
                    navigator.mediaDevices
                        .getUserMedia(constraints)
                        .then(stream => {
                            console.log("stream" + stream)
                            // _this.callExam();
                            UIkit.modal('#showQR').show()
                            store.commit('setloading', false);
                        })
                        .catch(error => {
                            store.commit('setloading', false);
                            // clearInterval(_this.interval)
                            // _this.waitTime = 0
                            alert("you need to allow your camera")
                            console.log("errorCallback орж ирсэн ", error);
                        });
                } else {
                    this.callExam()
                }
                store.commit('setloading', false);
            },
            callExam() {
                var _this = this
                if (navigator.userAgent.includes('Whale')) {
                    var sto;
                    if (typeof (window.browserStorage) !== "undefined") {
                        sto = window.browserStorage;
                    } else {
                        sto = localStorage;
                    }
                    sto.setItem('utoken', store.getters.getToken)
                    sto.setItem('studentid', store.getters.currentUser.id);
                    sto.setItem('loginId', store.getters.currentUser.username);
                    sto.setItem('urole', store.getters.currentUser.authorities[0].authority);
                    sto.setItem('uorganizId', store.getters.currentUser.organizs[0].organizId);
                    // sto.setItem('uImage', store.getters.currentUser.image);
                    var d = new Date();
                    var exdays = 1;
                    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
                    var expires = "expires=" + d.toUTCString();
                    document.cookie = "utoken=" + store.getters.getToken + ";" + expires + ";path=/";
                    document.cookie = "studentid=" + store.getters.currentUser.id + ";" + expires + ";path=/";
                    document.cookie = "loginId=" + store.getters.currentUser.username + ";" + expires + ";path=/";
                    document.cookie = "urole=" + store.getters.currentUser.authorities[0].authority + ";" + expires + ";path=/";
                    document.cookie = "uorganizId=" + store.getters.currentUser.organizs[0].organizId + ";" + expires + ";path=/";
                    // document.cookie = "uImage=" + store.getters.currentUser.image + ";" + expires + ";path=/";
                    if (window.examMode == 'undefined' || window.examMode === undefined) {
                        window.location.href = _this.localPath + "/take_exam?lang=" + _this.$i18n.locale +
                            "&examId=" + _this.waExam.eId + "&examCode=" + _this.waExam.eCd + "&variant=" + _this.waExam.vari +
                            "&qrSendID=" + _this.qrSendID + "&qrSendSecret=" + _this.qrSendSecret
                    } else {
                        window.examMode.requestExamMode(window.location.origin + _this.localPath + '/take_exam' +
                            "?lang=" + _this.$i18n.locale + "&examId=" + _this.waExam.eId + "&examCode=" + _this.waExam.eCd +
                            "&variant=" + _this.waExam.vari + "&qrSendID=" + _this.qrSendID + "&qrSendSecret=" + _this.qrSendSecret)

                        // window.examMode.requestExamMode('http://localhost:8084/exam/student/take_exam' +
                        //     "?lang=" + this.$i18n.locale + "&examId=" + _this.waExam.eId + "&examCode=" + _this.waExam.eCd +
                        //     "&variant=" + _this.waExam.vari + "&qrSendID=" + this.qrSendID + "&qrSendSecret=" + this.qrSendSecret)
                    }
                } else {
                    if (this.waExam.whUse == 1) {
                        alert(this.$t('whaleAllow'))
                        return
                    } else {
                        window.location.href = _this.localPath + "/take_exam?lang=" + _this.$i18n.locale +
                            "&examId=" + _this.waExam.eId + "&examCode=" + _this.waExam.eCd + "&variant=" + _this.waExam.vari +
                            "&qrSendID=" + _this.qrSendID + "&qrSendSecret=" + _this.qrSendSecret
                    }
                }
                store.commit('setloading', false);
            },
            async answerResults(exp, data) {
                try {
                    this.currentExamName = exp.examName;
                    let item = {
                        examId: exp.examId,
                        option: data
                    };
                    const response = await StudentService.getExamResult(item);
                    if (response.status == 200) {
                        this.examineeResultDetail = response.data.result.results
                    } else {
                        alert("error")
                    }
                } catch (error) {
                    console.log(error)
                }
            },
            async StudentHomeData() {
                try {
                    const response = await StudentService.StudentHomeData({});
                    this.myExamCount = response.data.result.myExamCount;
                    this.myOrganizCount = response.data.result.myOrganizCount;
                    this.latestexams = response.data.result.latestexams;
                    this.organizs = response.data.result.organizs;
                    this.myorganizs = response.data.result.myorganizs;
                } catch (error) {
                    console.log(error)
                }
            },
            async myexamlist(page) {
                try {
                    var status = '';

                    if (page > 0) {
                        page = page - 1;
                    }

                    // var js = window.localStorage.getItem('notice')
                    // if (js == true || js == null){
                    //   UIkit.modal("#showFirst").show();
                    // }

                    if (this.exam.gived) {
                        this.exam.gived = 1
                    }

                    if (this.exam.realExam) {
                        this.exam.realExam = 1
                    }
                    if (this.exam.date.length > 0) {
                        this.exam.startDate = this.exam.date[0];
                        this.exam.endDate = this.exam.date[1]
                    }
                    if (this.exam.status == 1) {
                        status = 'ready'
                    }
                    if (this.exam.status == 2) {
                        status = 'start'
                    }
                    if (this.exam.status == 3) {
                        status = 'finish'
                    }
                    if (this.exam.status == 4) {
                        status = "end"
                    }

                    let item = {
                        page: page,
                        realexam: this.exam.realExam,
                        gived: this.exam.gived,
                        status: status,
                        examname: this.exam.examName,
                        startDate: this.exam.date[0],
                        endDate: this.exam.date[1]
                    };
                    const response = await StudentService.getExamListStudent(item);

                    if (response.status == 200) {
                        this.myexams = []
                        for (let i = 0; i < response.data.content.length; i++) {
                            if (response.data.content[i].variantResult.length > 0) {
                                this.myexams.push(response.data.content[i])
                            } else {
                                console.log(response.data.content[i])
                            }
                        }
                        this.pages = response.data.totalPages;
                        if (!this.myexams.length) {
                            this.isLoading = false;
                        }
                    }
                } catch (error) {
                    this.error = error.response.data.message;
                    this.scrollToTop();
                }
            },
            noResultHere() {
                alert(this.$t('notAllowResult'));
                this.myexamlist(1)
            },
            notyetfinish() {
                alert(this.$t('notAllowStart'));
                this.myexamlist(1)
            },
            async ExamCodeRequest() {
                try {
                    UIkit.modal('#examCodeModal').hide()
                    const response = await StudentService.ExamCodeRequest({
                        examId: this.CurrentExam.examId
                    });
                    UIkit.modal('#examCodeModal').hide()
                    if (response.data.status == 200) {
                        if (response.data.success) {
                            alert(response.data.message);
                        } else {
                            alert(response.data.message);
                        }
                    }
                } catch (error) {
                    console.log(error)
                }
            },
            async StudentRequest(item) {
                try {
                    const response = await StudentService.StudentRequest({
                        organizId: item.organizId,
                        studentCode: this.currentUser.email,
                    });
                    if (response.data.status == 200) {
                        if (response.data.success == true) {
                            alert(response.data.message);
                        } else {
                            alert(response.data.message);
                        }
                    } else {
                        alert(response.data.message);
                    }
                } catch (error) {
                    console.log(error)
                }
            },
            async ExamCodeConfirm(inputval) {
                try {
                    const response = await StudentService.ExamCodeConfirm({
                        examcode: inputval
                    });
                    if (response.data.status == 200) {
                        if (response.data.success) {
                            alert(response.data.message);
                            this.myexamlist()
                        } else {
                            alert(response.data.message);
                        }
                    }
                } catch (error) {
                    console.log(error.response.data.message)
                }
            },
            scrollToTop() {
                window.scrollTo(0, 0);
            },
            onChange: function () {
                this.myexamlist(this.currentPage);
            },
            reload() {
                this.exam.examName = '',
                    this.exam.date = '',
                    this.exam.startDate = '',
                    this.exam.endDate = '',
                    this.exam.realExam = 0,
                    this.exam.status = '',
                    this.exam.gived = 0,
                    this.exam.todayExam = '',
                    this.exam.tomorrowExam = '',
                    this.exam.ungived = '',
                    this.myexamlist(1)
            }
        }
    }
    // const script = document.createElement('script');
    // script.async = true;
    // script.src = '../src/assets/javascript/qrcode.min.js';
    // document.head.appendChild(script);


</script>
<style scoped>
    /*@import '~fullcalendar/dist/fullcalendar.css';*/

    .uk-modal-footer {
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
    }

    .uk-modal-header {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }

    .uk-modal-dialog {
        border-radius: 15px;
    }

    .searchIcon >>> svg {
        width: 30px;
        margin: 9px;
    }

    .datepicker >>> input {
        height: 46px !important;
        border-radius: 30px !important;
        text-align: center;
        color: #0d9df9;
        border: none;
    }

    .datepicker >>> input::placeholder {
        color: #0d9df9;
    }

    .datepicker >>> .mx-input-append {
        margin-right: 18px;
    }

    .enterExamCode {
        width: 335px;
        height: 46px;
        text-align: center;
        border-radius: 30px;
        margin-right: 10px;
    }

    .enterExamCode::placeholder {
        font-size: 14px;
        color: #0d9df9;

    }

    .uk-button-result {
        background: #fff;
        height: 45px;
        color: #01B0FF;
        border-color: #01B0FF;
    }

    .uk-card-default {
        border: 1px solid;
        border-color: white;
        border-radius: 15px;
    }

    .mySpan {
        color: #a60ca8;
    }

    .card {
        border-radius: 15px;
        background-color: white;
        color: Black;
        box-shadow: #01B0FF;
        border-color: #8f8fcf
    }

    .slidenav {
        border-radius: 20px;
        background-color: #01B0FF;
        color: white;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 15px;
        padding-bottom: 15px;
    }


    .qList h2 {
        height: 30px;
        font-size: 1.6em;
        font-weight: 800;
        color: rgb(1, 176, 255);
    }


    .divid {
        border-bottom-style: inset;
        border-width: 10px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 50px;
        border-color: #01B0FF;
        width: 200px;
    }

    button {
        cursor: pointer;
    }

    tr td {
        vertical-align: middle;
    }

    tr th {
        text-align: center;
    }

    .uk-table td {
        padding: 14px 10px !important;
    }

    .btn-exam-disabled {
        width: 70px;
        height: 30px;
        color: #c0c0c0;
        background-color: #ffffff;
        border: 1px solid grey;
        border-radius: 3px;
        text-transform: capitalize;
        outline: none;
        border-radius: 30px;
    }

    .btn-exam-start {
        width: 70px;
        height: 30px;
        color: #fff;
        border: 1px solid #c0c0c0;
        border-radius: 3px;
        background: #0d9df9;
        text-transform: capitalize;
        outline: none;
        border-radius: 30px;
    }

    .btn-exam-result {
        width: 70px;
        height: 30px;
        border: 1px solid;
        background: #fff;
        text-transform: capitalize;
        margin-top: 5px;
        color: rgb(1, 176, 255);
        border-color: rgb(1, 176, 255);
        outline: none;
        border-radius: 30px;
    }

    .btn-exam-result:hover {
        border-color: rgb(1, 176, 255);
        color: #fff;
        background: rgb(1, 176, 255);
        border-radius: 30px;
    }

    .select-search {
        width: 360px;
        margin-right: 10px;
        height: 42px;
        border-radius: 30px;
        text-align: center;
        align-content: center;
        align-items: center;
        color: rgb(1, 176, 255);
        padding: 0px 1px 0px 1px;
    }

    .select-search option {
        color: #0d9df9;
    }

    .uk-select:not([multiple]):not([size]) {
        -webkit-appearance: none;
        -moz-appearance: none;
        padding-right: 20px;
        height: 46px;
        background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A);
        /*background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A),linear-gradient(#01b0ff, #38b8f2);*/
        background-repeat: no-repeat;
        background-position: 80% 50%;

    }

    /* The Modal (background) */
    .modal {
        position: fixed; /* Stay in place */
        z-index: 9999; /* Sit on top */
        left: 0;
        top: 0;
        bottom: 0;
        padding-top: 80px;
        padding-bottom: 80px;
        width: 100%;
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0, 0, 0); /* Fallback color */
        background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    }

    /* Modal Content */
    .modal-content {
        background-color: #fefefe;
        margin: auto;
        border: 1px solid #888;
        padding: 15px 10px;
        width: 70%;
        max-width: calc(100% - 0.01px) !important; /* Full width */
        margin-bottom: 100px;
    }

    /* The Close Button */
    .close {
        color: #aaaaaa;
        float: right;
        font-size: 28px;
        font-weight: bold;
    }

    .close:hover,
    .close:focus {
        color: #000;
        text-decoration: none;
        cursor: pointer;
    }

    .tabletitle {
        color: #fff !important;
        background: rgb(1, 176, 255) !important;
        vertical-align: top !important;
    }

    .tabletitlesub {
        background: #d5dde0 !important;
    }

    .uk-button-secondary {
        color: #fff !important;
        background: rgb(1, 176, 255) !important;
    }
</style>
