import axios from 'axios'
import store from '@/store/store'
import NProgress from 'nprogress'
import i18n from '../i18n'
export default () => {
    let headers = {
        'cache-control': 'public',
    };
    if (store.getters.getToken && store.getters.getToken !== '') {
        headers.Authorization = `Bearer ${store.getters.getToken}`
    }
    const instance = axios.create({
        // baseURL: 'http://111.218.67.49/api/',
        // baseURL: 'http://192.168.1.230:5005/api/',
        baseURL: '/api/',
        // baseURL: 'https://ubtcloud.me/api/',
        // baseURL: 'http://aws.ubtcloud.me/api/',
        headers: headers
    });
    instance.interceptors.request.use(
        config => {
            var locale = "";

            if (i18n.locale == 'kr') {
                locale = 'kr-KR'
            }
            if (i18n.locale == 'mn') {
                locale = 'mn-MN'
            }
            if (i18n.locale == 'en') {
                locale = 'en-EN'
            }
            if (i18n.locale == 'in') {
                locale = 'in-IN'
            }
            config.headers['Accept-Language'] = locale;
            NProgress.start();
            store.commit('setloading', true);
            return config
        },
        error => {
            NProgress.done();
            store.commit('setloading', false);
            return Promise.reject(error)
        }
    );

    instance.interceptors.response.use(response => {
        store.commit('setloading', false);
        NProgress.done();
        return response
    }, (error) => {
        if (error.response.data.status === 401) {
            store.dispatch('setToken', null);
            store.dispatch('setUser', null);
            store.dispatch('getters', null);
            window.location.href = "/login";
        }
        store.commit('setloading', false);
        NProgress.done();
        return Promise.reject(error);
    });

    return instance
}
