const state = {
  token: null,
  user: null,
  isUserLoggedIn: false

}

const mutations = {
  setToken(state, token) {
    state.token = token
    state.isUserLoggedIn = !!(token)
  },
  setUser(state, user) {
    state.user = user
  }
}
const getters = {
  isLoggedIn: (state) => {
    return state.isUserLoggedIn
  },
  getToken: (state) => {
    return state.token
  },
  currentUser: (state) => {
    return state.user
  }
}

export default {
  state,
  mutations,
  getters
}
