const state = {
  isLoader: false
}

const mutations = {
  setloading(state, isLoading) {
    // console.log(isLoading)
    state.isLoader = isLoading;
  }
}
const getters = {
  isLoading: (state) => {
    return state.isLoader
  }
}

export default {
  state,
  mutations,
  getters
}
