const state = {
  cartItemList: []
}

const mutations = {
  setToken (state, token) {
    state.token = token
    state.isUserLoggedIn = !!(token)
  },
  setUser (state, user) {
    state.user = user
  },

  UPDATE_CART (state, { item, quantity, isAdd, type }) {
    const record = state.cartItemList.find((element) => element.id === item.id && element.type === item.type)
    if (record) {
      if (isAdd) {
        record.quantity += quantity
      } else {
        record.quantity = quantity
      }
    } else {
      state.cartItemList.push({
        ...item,
        quantity,
        type
      })
    }
  },
  SET_CART (state, productList) {
    if (productList) {
      state.cartItemList = productList
    }
  },
  REMOVE_CART_ITEM (state, { item }) {
    const record = state.cartItemList.find((element) => element.id === item.id)
    state.cartItemList.splice(state.cartItemList.indexOf(record), 1)
  }
}

const actions = {
  clearCart: ({ commit }) => {
    commit('SET_CART', [])
  }
}

const getters = {
  cartItemList: (state) => {
    return state.cartItemList
  },
  cartValue: (state) => {
    let res = 0
    state.cartItemList.map((item) => {
      res += item.price * item.quantity
    })
    return res
  },
  cartValueDiscount: (state) => {
    let resdis = 0
    state.cartItemList.map((item) => {
      resdis += item.price * item.quantity - ((item.price * item.quantity * item.discount) / 100)
    })
    return resdis
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
