import Api from '@/services/Api'

const resource = '/student'
export default {

  getStudentExamCodes(params = {}) {
    return Api().get(`${resource}/examcodes`, {
      params: params
    })
  },
  getOrganizationsExamList(params = {}) {
    return Api().get(`${resource}/organizations/examlist`, {
      params: params
    })
  },
  getReTakeResult(credentials) {
    return Api().post(`/webexam/seelog`, credentials)
  },
  sendingQRfiles(credentials) {
    return Api().post(`/webexam/qrgenerate`, credentials)
  },
  getUserOrganizationsExamList(params = {}) {
    return Api().get(`${resource}/userorganizations/examlist`, {
      params: params
    })
  },

  getCalendarExams(params = {}) {
    return Api().get(`/mobile/calendar/examcodes`, {
      params: params
    })
  },
  StudentHomeData(params = {}) {
    return Api().get(`/mobile/homedata`, {
      params: params
    })
  },
  getExamListStudent(params = {}) {
    return Api().get(`/mobile/examcodes`, {
      params: params
    })
  },
  // exam result see
  getExamResult(params = {}) {
    return Api().get(`/mobile/exam/result/?size=12`, {
      params: params
    })
  },
  // exam code awah hvselt ywuulah
  ExamCodeRequest(params = {}) {
    return Api().get(`/mobile/examregister`, {
      params: params
    })
  },
  // exam code хүсэлт явуулсанг зөвшөөрөх
  ExamCodeConfirm(params = {}) {
    return Api().get(`/mobile/examcode/enable`, {
      params: params
    })
  },
  // бүх шалгалтаас шалгалтын нэрээр нь хайх лист-ээр хайх
  AllExamList(params = {}) {
    return Api().get(`/mobile/other/exams`, {
      params: params
    })
  },
  StudentAllExamList(params = {}) {
    return Api().get(`/mobile/other/exams`, {
      params: params
    })
  },
  // Тухайн байгуулагаас зарлагдсан шалгалтууд харах
  OtherExamsList(params = {}){
    return Api().get(`/mobile/organiz/examlist`,{
      params: params
    })
  },
  // Хэрэглэгчийн сонгосон байгуулагын лист
  MyOrganizationList(params = {}) {
    return Api().get(`/mobile/organizs`, {
      params: params
    })
  },
  // Хэрэглэгчид хамааралгүй байгуулагын лист
  AllOrganizationList(params = {}) {
    return Api().get(`/mobile/organizs`, {
      params: params
    })
  },
  // Байгуулагад бүртгүүлэх хүсэлт "Сургуульд бүртгүүлэхээр бол сурагчын код-оор бүртгүүлнэ"
  StudentRequest(params = {}) {
    return Api().get(`/mobile/studentrequest`, {
      params: params
    })
  },
  getExamQuestions(params = {}) {
    return Api().get(`/mobile/exam/questions`, {
      params: params
    })
  },
  // result send section
  resultSend(credentials) {
    return Api().post(`/mobile/exam/result/upload`, credentials)
  },

  getExamResultSee(params = {}){
    return Api().get(`mobile/exam/result`, {
      params: params
    })
  },

}
