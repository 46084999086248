<template>
  <div>
    <div style="position: fixed;display: none;
    z-index: 99999;
    width: 100%;
    height: 100%;
    text-align: center;
    margin: auto;
    background: #0009;" v-show="isLoading">
      <div class="lds-ellipsis_ddd">
        <img
          width="70"
          height="70"
          data-src='/uploadingDir/ubtloading.gif'
          data-uk-img
        />
      </div>
      <div style="color: #ffffdd;">Loading...</div>
    </div>
    <component :is="layout">
      <transition name="fade" mode="out-in">
        <router-view :layout.sync="layout"/>
      </transition>
    </component>
  </div>
</template>

<script scoped>

  import {mapGetters} from 'vuex'
  import store from '@/store/store'
  export default {
    name: `App`,
    components: {},
    computed: {
      ...mapGetters(['isLoading'])
    },
    created() {
      store.commit('setloading', false)
    },
    data() {
      return {
        layout: `div`,
      }
    },
    methods:{
    }
  }
</script>

<style scoped>
  /** https://loading.io/css/ */
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 75px;
    height: 64px;
  }

  .lds-ellipsis div {
    position: absolute;
    top: 27px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #ddd;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    background: #f7ecfc;
  }

  .lds-ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;

  }

  .lds-ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }

  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(19px, 0);
    }
  }
</style>
